import React, { useState, useEffect } from 'react'
import { makeStyles } from '@mui/styles';
import { IconButton } from '@mui/material';
import { Box } from '@mui/system';
import RenderMenuItem from './RenderMenuItem';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import '../../styles/documentation.css';

const useStyles = makeStyles((theme) => ({
  tocContainer: {
    // backgroundColor: 'rgba(237, 242, 252, 1)',
    padding: '2em 4rem',
    // height: '100%',
    position: 'relative',
    [theme.breakpoints.down('xl')]: {
      padding: '1.5em 2rem',
    }
  },
  sectionLogo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: '10px',
    marginBottom: '25px',
  },
  titleStyle: {
    color: 'rgba(20, 86, 158, 1)',
    fontSize: '17px',
    fontWeight: 200,
    marginLeft: '15px',
  },
  headerSection: {
    color: 'rgba(32, 21, 73, 1)',
    fontWeight: 800,
    fontSize: '14px',
    cursor: 'pointer',
    textDecoration: 'none',
  },
  subsectionStyle: {
    color: 'rgba(69, 69, 69, 1)',
    fontWeight: 400,
    fontSize: '14px',
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      color: 'rgba(20, 86, 158, 1) !important',
    },
  },
  sectionAreaContainer: {
    minHeight: '40vh',
  },
}));

const SideBarDocumentation = ({ toc, hash, stateBox, setStateBox }) => {
  const classes = useStyles();
  // const handleToTop = () => {
  //   window.scrollTo(0, 0);
  // };
  // const [isRendered, setIsRendered] = useState(true);
  const [scrollPosition, setScrollPosition] = useState(0);
  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;
      setScrollPosition(position);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const thresholdScrollDistance = 150;
  const positionY = 150;
  const shouldPositionComponent = scrollPosition > thresholdScrollDistance;
  const renderSection = (section) => (
    <RenderMenuItem hash={hash} section={section} key={section.id} />
  );

  return (
    <Box
      component="div"
      className={classes.tocContainer}
      sx={{ top: shouldPositionComponent ? 0 : positionY }}>
      <IconButton
        onClick={() => setStateBox(!stateBox)} 
        sx={{
          position: 'fixed',
          zIndex: 6,
          marginTop: '-20px',
          left: '0px',
          transform: !stateBox ? 'scaleX(-1)' : 'scaleX(1)'
        }} 
        className='arrowStyleForMenu'
      >
        <DoubleArrowIcon />
      </IconButton>
      <Box sx={{display: stateBox ? 'block' : 'none'}}>
      <Box component="div" className={classes.sectionLogo}>
        <Box component="img" src={"/logo_doc.png"} alt="logo" width={50} />
        <Box component="div" className={classes.titleStyle}>
          Documentación
        </Box>
      </Box>
      <Box className={classes.sectionAreaContainer}>
        {toc.sections.map(renderSection)}
      </Box>
      </Box>
    </Box>
  );
};

export default SideBarDocumentation;