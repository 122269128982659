import React, { useEffect, useState } from 'react';
import { Grid, TextField, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';
import { getProjectTemplates } from 'api-lofty';
import Layout from '../layouts/Layout';
import CardTemplate from '../components/CardTemplate/cardTemplate';
import { useHistory } from 'react-router-dom';
import '../styles/templates.css';

const useStyles = makeStyles((theme) => ({
  headerSection: {
    width: '90%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '20px',
    marginBottom: '20px',
    [theme.breakpoints.down('601')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#CFD6E6',
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginTop: '15px',
      width: 'auto',
    },
  },
  searchTextField: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    transition: theme.transitions.create('width'),
    width: '95%',
    [theme.breakpoints.up('md')]: {
      width: '50ch',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    left: '-1.5rem',
    height: '100%',
    marginLeft: 10,
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  titleStyle: {
    fontWeight: 700,
    fontSize: '32px',
    color: 'rgba(20, 86, 158, 1)',
    [theme.breakpoints.down('1025')]: {
      fontSize: '24px',
    },
  },
}))

const TemplatesPage = () => {
  const [templateData, setTemplateData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const data = await getProjectTemplates();
        setTemplateData(data.data);
        setFilteredData(data.data);
      } catch (error) {
        console.error('Error fetching templates:', error);
      }
    };
    fetchTemplates();
  }, []);

  const handleSearchTemplate = (e) => {
    const searchString = e.target.value.toLowerCase();
    const filteredTemplates = templateData.filter(template =>
      template.name.toLowerCase().includes(searchString)
    );
    setFilteredData(filteredTemplates);
  };

  const handleClickTemplate = (template) => {
    history.push(`/previewtemplate/${template._id}`, { templateDetails: template });
  };

  return (
    <Layout>
      <Box style={{ backgroundColor: 'rgba(243, 243, 243, 1)' }}>
        <header className="header-template-landing-style">
          <Box className={classes.headerSection}>
            <Box className={classes.titleStyle}>
              Plantillas
            </Box>
            <div className={classes.search}>
              <Box className={classes.searchIcon}>
                <SearchIcon />
              </Box>
              <TextField
                size="small"
                placeholder="Buscar plantilla.."
                classes={{ root: classes.searchTextField }}
                variant="outlined"
                onChange={handleSearchTemplate}
              />
            </div>
          </Box>
          <div className="divisor-header-template" />
        </header>
        <main>
          <div style={{ marginTop: '30px', padding: '3rem', marginBottom: '100px' }}>
            <Grid container spacing={3}>
              {filteredData.length === 0 ? (
                <Grid item md={12} style={{ marginTop: '15vh', marginBottom: '15vh' }}>
                  <h2 style={{ textAlign: 'center', width: '100%' }}>
                    No hay Plantillas por el momento
                  </h2>
                </Grid>
              ) : (
                filteredData.map((template) => (
                  <Grid alignItems="stretch" item xl={3} lg={4} md={6} sm={12} key={template._id}>
                    <CardTemplate
                      name={template.name}
                      description={template.description}
                      price={template.price}
                      url={template.slug}
                      idTemplate={template._id}
                      onClick={() => handleClickTemplate(template)}
                    />
                  </Grid>
                ))
              )}
            </Grid>
          </div>
        </main>
      </Box>
    </Layout>
  );
}

export default TemplatesPage;