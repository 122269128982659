import React from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, Paper, TextField, Button, Hidden } from '@mui/material';
import { Box } from '@mui/system';
import img from "../img/dotsDarker.png";
import { addSuscription } from '../api/subscription';
import dotsMobile from '../img/dotsDarkerMobile.png';

const Contact = () => {
  const classes = useStyles();
  return (
    <div className={`${classes.section}`} id="Contactenos">
      <Box className={classes.boxMainTitleSection}>
        {/* <Hidden only={['sm', 'xs']}>
          <Box className={classes.pictureTitleSection}>
            <img src={img} alt="Logo" className={classes.sectionCard} />
          </Box>
        </Hidden>
        <Hidden only={['md', 'xl', 'lg']}>
          <Box className={classes.pictureTitleSection}>
            <img src={imgMovil} alt="Logo" className={classes.sectionCard} />
          </Box>
        </Hidden> */}
        <Hidden smDown>
            <Box component="img" src={img} alt="dots" className={classes.imageStyle} />
          </Hidden>
          <Hidden smUp>
            <Box component="img" src={dotsMobile} alt="dots" className={classes.imageStyle} />
          </Hidden>
        <Box className={classes.boxZero}>
          <Box className={classes.titleContact}>
            <b>Deja un </b> Mensaje
          </Box>
          {/* <Hidden only={['xs','sm']}>
          <p >
            Contáctate con nosotros si tienes dudas, consultas, sugerencias o lo que se te ocurra acerca de LoftyApps
          </p>
          </Hidden> */}
        </Box>
      </Box>
      <Box className={classes.containerBox}>
        <Box className={classes.containerInfoBox}>
          <p>
            Nos complace enormemente recibir tus comentarios, ya que en LoftyApps valoramos cada una de tus palabras. Tu retroalimentación es esencial para mantenernos actualizados sobre tus necesidades y brindarte un servicio excepcional.
          </p>
        </Box>
        <Box className={classes.containerForm}>
          <Paper className={classes.borderContact}>
            <form
              onSubmit={async (e) => {
                e.preventDefault();
                try {
                  await addSuscription({
                    email: e.target.email.value,
                    name: e.target.name.value,
                    message: e.target.message.value
                  });
                  alert('Se ha enviado de manera exitosa');
                } catch (error) {
                  console.log(error.response?.data);
                  if (error.info) {
                    alert(error.info);
                  } else {
                    alert('Error al enviar por favor verifique los campos')
                  }
                }
              }}
            >
              <Grid spacing={4} container>
                <Grid item sm={6} xs={12}>
                  <TextField
                    label="Nombre"
                    name='name'
                    type="text"
                    variant="standard"
                    fullWidth
                    margin='normal'
                    required
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    label="Correo"
                    type="email"
                    name='email'
                    variant="standard"
                    fullWidth
                    margin='normal'
                    required
                  />
                </Grid>
                <Grid item sm={12} xs={12}>
                  <TextField
                    label="Mensaje"
                    type="text"
                    name='message'
                    variant="standard"
                    multiline
                    rows={2}
                    fullWidth
                    margin='normal'
                  />
                </Grid>
              </Grid>
              <div style={{ textAlign: 'center', marginTop: '2rem' }}>
                <Button type='submit' variant='contained'>
                  Enviar
                </Button>
              </div>
            </form>
          </Paper>
        </Box>
      </Box>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  imageStyle: {
    width: '420px',
    height: '100px',
    [theme.breakpoints.down("md")]: {
      width: '300px',
      height: '90px',
    },
    [theme.breakpoints.down("sm")]: {
      width: 'auto',
      height: '40px',
    },
  },
  boxMainTitleSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    // alignContent: 'center',
    // alignItems: 'center',
    width: '100%',
    height: '200px',
  },
  pictureTitleSection: {
    display: 'flex',
    width: '30%',
    height: 'auto',
    [theme.breakpoints.down("sm")]: {
      width: '50%',
    },
  },
  containerBox: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    [theme.breakpoints.down("sm")]: {
      flexDirection: 'column',
    },
  },
  containerInfoBox: {
    display: 'flex',
    flexDirection: 'column',
    width: '55%',
    backgroundColor: 'rgba(32, 21, 73, 1)',
    color: '#fff',
    fontSize: '18px',
    padding: '5rem',
    justifyContent: 'flex-end',
    [theme.breakpoints.down("sm")]: {
      width: '100%',
      fontSize: '14px',
      padding: '1rem',
    },
  },
  containerForm: {
    width: '45%',
    [theme.breakpoints.down("sm")]: {
      width: '100%',
    },
  },
  section: {
    color: "#29B2EF",
    background: "#FCFCFC",
    fontFamily: 'Roboto',
    fontStyle: "normal",
    marginBottom: '5rem',
  },
  sectionCard: {
    paddingTop: '5rem',
    paddingLeft: '5rem',
    paddingBottom: '3rem',
    paddingRight: '1rem',
    [theme.breakpoints.down("sm")]: {
      paddingLeft: '2.5rem',
      paddingRight: '0rem',
    },
  },
  borderContact: {
    padding: '2rem',
    boxShadow: '0px 4px 6px #1345A6!important',
    borderRadius: '5px!important',
  },
  boxZero: {
    width: 'auto',
    // paddingTop: '3rem',
    display: 'flex',
    marginLeft: '15px',
    alignItems: 'flex-start',
    flexDirection: 'column',
    color: 'rgba(20, 73, 153, 1)',
    justifyContent: 'flex-start',
    height: '100px',
    [theme.breakpoints.down("md")]: {
      justifyContent: 'center',
      height: '90px',
    },
    [theme.breakpoints.down("sm")]: {
      alignItems: 'flex-start',
      justifyContent: 'center',
      // width: '50%',
      paddingTop: '1px',
      height: '40px',
    },
  },
  titleContact: {
    fontSize: '2.5em',
    [theme.breakpoints.down("md")]: {
      fontSize: "1.7em",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: '1.2em',
    },
  }
}))

export default Contact