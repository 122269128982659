import React from 'react'
import { Box } from '@mui/system';
import iconCheck from '../img/price_plan/check_circle.svg';


const CardPricePlan = ({
  type,
  price,
  collaborators,
  storage,
  included,
  onClick,
}) => {
  return (
    <Box className='pricing-card-price-plan-subscription' style={{ marginTop: '20px', marginBottom: '20px' }}>
      <Box className='pricing-card-price-plan-subscription-left-wing'>
        <Box className='pricing-card-price-plan-subscription-title'>
          {type}
        </Box>
        <Box className='pricing-card-price-plan-subscription-cost'>
          HNL {`${price} `} Anual
        </Box>
        <Box className='pricing-card-price-plan-subscription-content'>
          Colaboradores {` ${collaborators}`}
        </Box>
        <Box className='pricing-card-price-plan-subscription-content'>
          Almacenamiento {` ${storage}`}
        </Box>
        <button onClick={onClick} className='pricing-card-price-plan-subscription-button'>
          Agendar cita
        </button>
      </Box>
      <Box className='pricing-card-price-plan-subscription-right-wing'>
        <Box className='pricing-card-price-plan-subscription-include-title'>
          Que Incluye
        </Box>
        <Box style={{marginTop: '10px', marginBottom: '10px'}}>
          {included.map((index) => (
            <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginTop: '10px', marginBottom: '10px' }}>
              <img src={iconCheck} alt="checkicon" style={{marginRight: '10px'}} />
              <div className='pricing-card-price-plan-subscription-include-item-text'>
                {index.feats}
              </div>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  )
}

export default CardPricePlan;