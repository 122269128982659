import React from 'react'
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import { Button } from '@mui/material';
import imageIcon from '../img/consult_export/logo.png';

const useStyles = makeStyles((theme) => ({
  boxMainContainer: {
    width: '100%',
    height: '490px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    background: '#16579F',
    [theme.breakpoints.down('601')] : {
      flexDirection: 'column',
      height: 'auto',
    },
  },
  boxInfoContainer: {
    width: '45%',
    padding: '50px',
    marginLeft: '2em',
    [theme.breakpoints.down('1025')] : {
      width: '60%',
      marginLeft: '1rem',
      padding: '30px',
    },
    [theme.breakpoints.down('601')] : {
      width: '100%',
      margin: 0,
      padding: '0',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: '20px',
    },
  },
  titleInfo: {
    color: '#FFF',
    fontSize: '49px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '55px', /* 112.245% */
    margin: '15px',
    [theme.breakpoints.down('1025')] : {
      fontSize: '38px',
      margin: '10px',
    },
    [theme.breakpoints.down('601')] : {
      textAlign: 'center',
      margin: '15px',
    },
  },
  subtitleInfo: {
    color: '#29B2EF',
    fontSize: '25px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '33px', /* 132% */
    margin: '15px',
    [theme.breakpoints.down('1025')] : {
      fontSize: '18px',
      margin: '10px',
    },
    [theme.breakpoints.down('601')] : {
      textAlign: 'center',
      margin: '15px',
    },
  },
  buttonStyle: {
    borderRadius: '5px',
    background: '#29B2EF',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    color: '#201549',
    textAlign: 'center',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    margin: '15px',
    whiteSpace: 'pre-wrap',
    [theme.breakpoints.down('1025')] : {
      fontSize: '18px',
      margin: '10px',
    },
    [theme.breakpoints.down('601')] : {
      alignSelf: 'center',
      margin: '15px',
    },
  },
  imageContainer: {
    height: '100%',
    width: '25%',
    background: 'rgba(41, 178, 239, 0.2)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('1025')] : {
      width: '40%'
    },
    [theme.breakpoints.down('601')] : {
      width: '100%',
      height: 'auto',
    },
  },
  imageStyle: {
    width: '70%',
    objectFit: 'contain',
    alignSelf: 'center',
    [theme.breakpoints.down('1025')] : {
      width: '80%'
    },
    [theme.breakpoints.down('601')] : {
      width: '50%'
    },
  },
}))

const ConsultSection = ({
  history
}) => {
  const classes = useStyles();
  return (
    <Box component="div" className={classes.boxMainContainer}>
      <Box component="div" className={classes.boxInfoContainer}>
        <Box component="div" className={classes.titleInfo}>
          ¿Necesitas ayuda para desarrollar tu idea inicial?
        </Box>
        <Box component="div" className={classes.subtitleInfo}>
          Estamos disponibles para discutir y perfeccionar tu concepto.
        </Box>
        <Button
          variant="contained"
          size="large"
          className={classes.buttonStyle}
          onClick={() => {
            history.push('/calendar');
          }}
        >
          {'HNL 450.00 \n Por Hora Consultoria'}
        </Button>
      </Box>
      <Box component="div" className={classes.imageContainer}>
        <Box component="img" src={imageIcon} className={classes.imageStyle} />
      </Box>
    </Box>
  )
}

export default ConsultSection;