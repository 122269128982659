import React from 'react'
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';

const useStyles = makeStyles((theme) => ({
  tocContainer: {
    backgroundColor: 'rgba(255, 255, 255, 1)',
    padding: '2em 5em',
    // height: '100%',
    // overflowY: 'scroll',
    minHeight: '700px',
  },
  subsectionTitle: {
    color: 'rgba(8, 8, 8, 1)',
    fontWeight: 500,
    fontSize: '42px',
    marginTop: '20px',
    marginBottom: '50px',
    [theme.breakpoints.down('1025')] : {
      fontSize: '32px',
      textAlign: 'center',
    },
  },
  listInfoStyle: {
    color: 'rgba(8, 8, 8, 1)',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '26px',
    marginTop: '20px',
    marginBottom: '20px',
  },
  normalTextinfo: {
    color: 'rgba(8, 8, 8, 1)',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '26px',
    marginTop: '30px',
    marginBottom: '30px',
    textAlign: 'justify',
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '30px',
    marginBottom: '30px',
  },
  boldDescriptionImage: {
    color: 'rgba(8, 8, 8, 1)',
    fontWeight: 800,
    fontSize: '16px',
    lineHeight: '26px',
    marginTop: '20px',
    marginBottom: '20px',
  },
  divisorStyle: {
    backgroundColor: 'rgba(20, 86, 158, 1)',
    height: '2px',
    marginTop: '30px',
    marginBottom: '50px',
  },
  imageStyle: {
    width: 'auto',
    [theme.breakpoints.down('600')] : {
      width: '70vw',
      objectFit: 'contain',
    },
  }
}));

const ContentDocumentation = ({ toc, sourceMultimedia }) => {
  const classes = useStyles();
  const renderSection = (subsection) => (
    <Box key={subsection.id}>
      <Box component="div" className={classes.subsectionTitle}>
        {subsection.title}
      </Box>
      <Box component="div" className={classes.divisorStyle} />
      <Box>
        {subsection.content.map((index) => {
          if (index?.listData && index?.listData.length > 0) {
            return (
              <Box component="ul">
                {index.listData.map((info) => (
                  <Box component="li" className={classes.listInfoStyle}>
                    <b>{info.bulletPoint}</b> {info.infoBulletPoint}
                  </Box>
                ))}
              </Box>
            )
          }
          if (index?.imagedata) {
            return (
              <Box component="div" className={classes.imageContainer}>
                <Box component="img" src={`/${sourceMultimedia}/${index.imagedata}.png`} className={classes.imageStyle}/>
                <Box className={classes.boldDescriptionImage}>{index?.descriptionImage}</Box>
              </Box>
            )
          }
          return (
            <Box component="div" className={classes.normalTextinfo}>
              {index.textinfo}
            </Box>
          )
        })}
      </Box>
    </Box>
  );

  return (
    <Box component="div" className={classes.tocContainer}>
      {toc && toc?.content ? (
        renderSection(toc)
      ) : (
        <Box>
          Loading
        </Box>
      )
      }
    </Box>
  );
};

export default ContentDocumentation;