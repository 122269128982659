import React from 'react';
import Navbar2 from '../components/Navbar/Navbar2';
import Footer2 from '../components/Footer/Footer2';

const Layout = ({children}) => {
  return (
    <div>
      <Navbar2 />
      {children}
      <Footer2 />
    </div>
  )
}

export default Layout;
