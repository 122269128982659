// import { Button, Card, CardContent } from '@mui/material';
import React from 'react'
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';

const BlogCard = ({
  image,
  type,
  title,
  onClick,
}) => {
  const classes = useStyles();
  return (
    <Box component="div" className={classes.boxContainer} onClick={() => onClick()}>
      <Box component="img" src={image} className={classes.imageStyle} />
      <Box component="div" className={classes.typeContainer}>
        {type}
      </Box>
      <Box component="div" className={classes.titleStyle}>
        {title}
      </Box>
    </Box>
  )
}


const useStyles = makeStyles((theme) => ({
  boxContainer: {
    display: 'flex',
    padding: '16px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    // gap: 16px;
    borderRadius: '12px',
    border: '1px solid #E8E8EA',
    background: '#fff',
  },
  imageStyle: {
    height: '240px',
    width: '100%',
    objectFit: 'cover',
    borderRadius: '6px',
    [theme.breakpoints.down("800")]: {
      height: '150px !important',
    },
  },
  typeContainer: {
    display: 'flex',
    alignSelf: 'flex-start',
    padding: '4px 10px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '6px',
    background: 'rgba(75, 107, 251, 0.05)',
    // gap: 4px;
    color: 'rgba(75, 107, 251, 1)',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px', /* 142.857% */
    marginTop: '10px',
    marginBottom: '10px',
  },
  titleStyle: {
    color: 'rgba(24, 26, 42, 1)',
    /* Heading/h3/Semibold */
    // font-family: Work Sans;
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '28px', /* 116.667% */
    [theme.breakpoints.down("800")]: {
      fontSize: '15px',
      lineHeight: '18px',
    },
  },
}))

export default BlogCard;

