import React, { useState } from 'react';
import { Grid, Container, } from '@mui/material';
import Layout from '../layouts/Layout';

const questions = [
  {
    questionTitle: '¿Qué es Lofty Apps?',
    questionAnswer: 'Lofty Apps es una plataforma que te permite crear tus propias aplicaciones web de manera mucho más rápida y sencilla que con los métodos tradicionales.'
  },
  {
    questionTitle: '¿Qué puedo crear con Lofty Apps?',
    questionAnswer: 'Nos encontramos en constante crecimiento y actualmente con Lofty Apps, las personas son capaces de crear sitios web cómo e-commerce, herramientas administrativas, sitios informativos y más.'
  },
  {
    questionTitle: '¿Qué incluye el pago de suscripción de despliegue de un sitio?',
    questionAnswer: 'Incluye el alojamiento de tu aplicación hecha con Lofty Apps, con el acceso a todos los plugins. No incluye el dominio.'
  },
  {
    questionTitle: '¿Puedo comprar dominios en Lofty Apps?',
    questionAnswer: 'Actualmente no es posible comprar dominios desde la plataforma, pero puedes asignar un dominio comprado en un proveedor externo.'
  },
  {
    questionTitle: '¿Qué es la pasarela de pago de Lofty Apps?',
    questionAnswer: `Es el medio por el cuál puedes hacer cobros mediante tarjeta de crédito para tu aplicación.
    Puedes agregar este método de pago en el editor de páginas de Lofty Apps a cualquier parte de tu aplicación.`
  },
  {
    questionTitle: '¿Puede mi cliente usar la pasarela de pago de Lofty Apps si está fuera del país (Honduras)?',
    questionAnswer: 'Actualmente no contamos con cobertura de nuestra pasarela fuera de Honduras, si deseas recibir pagos por tus servicios o productos, puedes usar otro método de pago, como ser Paypal o Stripe.'
  },
  {
    questionTitle: '¿Qué necesito para poder recibir pagos de Lofty Apps en Honduras?',
    questionAnswer: 'Para cuentas personales es necesario brindar un número de cuenta de banco, y el nombre completo y número de identidad del titular de dicha cuenta. Para cuentas empresariales se requiere del número de cuenta, nombre y R.T.N. de la sociedad.'
  },
  {
    questionTitle: '¿Puedo recibir pagos de Lofty Apps si no poseo cuenta en Banco Atlántida?',
    questionAnswer: 'Es posible; sin embargo, es necesario tener en cuenta que el monto correspondiente a través de ACH será deducido del total enviado.'
  },
  {
    questionTitle: '¿Cada cuánto recibo el dinero de los pagos realizados con la pasarela de Lofty Apps?',
    questionAnswer: 'Los pagos se realizan aproximadamente cada dos semanas como depósito a la cuenta que hayas escogido.'
  },
  {
    questionTitle: '¿Lofty Apps aplica alguna comisión o recargo por hacer uso de su pasarela de pago? En caso afirmativo, ¿cuál es el monto de dicha comisión?',
    questionAnswer: 'La comisión por transacción es del 4.50% (donde el Banco Atlántida le pertenece el 3.80% y a Lofty Apps el 0.70%) más $0.25 de dólar (donde el Banco Atlántida le pertenece $0.15 y a Lofty Apps $0.10).'
  },
  {
    questionTitle: '¿Habría algún cargo adicional por hacer uso de la pasarela de pagos? ',
    questionAnswer: 'No, eso abarca la totalidad de los costos asociados para la pasarela de pagos.'
  },
]

const FAQ = ({ history }) => {
  const [openedQuestions, setOpenedQuestions] = useState(new Array(questions.length).fill(false));

  const handleShowQuestion = (index) => {
    const openedQuestionsTemp = [...openedQuestions];
    openedQuestionsTemp[index] = !openedQuestionsTemp[index];
    setOpenedQuestions(openedQuestionsTemp);
  }

  return (
    <Layout>
      <header>
        <section className='section-loftyapps'>
          <Container maxWidth="xl" className='padding-container-loftyapps'>
            <Grid container>
              <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} item xs={12} >
                <div className="faq-title-container">
                  Preguntas Frecuentes
                </div>
              </Grid>
            </Grid>
          </Container>
        </section>
      </header>
      <main>
        <section className='questions-section'>
          <div className='questions-container'>
            {questions.map((question, index) => (
              <div className='question' key={question.questionTitle}>
                <div className={`question-number ${openedQuestions[index] ? '' : 'number-hidden'}`}>
                  {`${index + 1}`.padStart(2, 0)}
                </div>
                <div className='question-text'>
                  <div className='question-title'>
                    {question.questionTitle}
                  </div>
                  <div className={`question-answer ${openedQuestions[index] ? '' : 'answer-hidden'}`}>
                    {question.questionAnswer}
                  </div>
                </div>
                <div className='plus-column'>
                  <div
                    className={`plus-container ${openedQuestions[index] ? 'rotated' : ''}`}
                    onClick={() => handleShowQuestion(index)}>
                    <svg
                      className={`plus ${openedQuestions[index] ? 'cross' : ''}`}
                      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
                      <defs />
                      <path d="M26 0h12v64H26z" />
                      <path d="M0 38V26h64v12z" />
                    </svg>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
        <section style={{ height: 200 }} className='section-white-loftyapps' />
      </main>
    </Layout>
  );
}

export default FAQ;
