import { Avatar, Card, CardContent } from '@mui/material'
import React from 'react'
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import quotation from '../../img/quotation.png';


const CardTestimonials = ({ name, title, description, url }) => {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <CardContent>
        <Box className={classes.box}>
          <Avatar className={classes.icon} alt="Image" src={url} />
        </Box>
        <Box className={classes.box}>
          <p className={classes.cardName} >
            {name}<br></br>
            <span className={classes.cardSubTitle}>{title}</span>
          </p>
        </Box>
        <Box className={classes.boxDescription}>
          <img alt="imgQuotation" src={quotation} className={classes.imgQuotation}></img>
          <p className={classes.cardDescription} >{description}</p>
          <img alt="imgQuotationRotation" src={quotation} className={classes.imgQuotationRotation} ></img>
        </Box>
      </CardContent>
    </Card>
  )
}

const useStyles = makeStyles(() => ({
  card: {
    minHeight: "250px",
    border: "0.5px solid #1345A6",
    boxSizing: "border-box",
    borderRadius: "5px!important",
    color: "#201549!important",
  },
  cardName: {
    color: '#201549!important',
    fontSize: "18px",
    lineHeight: "21px",
    fontWeight: 'Bold',
    textAlign: 'center'
  },
  icon: {
    width: '4rem!important',
    height: '4rem!important'
  },
  box: {
    paddingTop: '2rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexFlow: 'row nowrap',
    color: '#201549',
  },
  boxDescription: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexFlow: 'row nowrap',
    color: '#201549',
  },
  imgQuotation: {
    width: '40px',
    height: '40px',
  },
  imgQuotationRotation:{
    marginTop:'6rem',
    width: '40px',
    height: '40px',
    transform: 'rotate(-180deg)',
  },
  cardSubTitle: {
    color: '#201549!important',
    fontSize: "12px",
    lineHeight: "14px",
  },
  cardDescription: {
    color: '#201549!important',
    fontSize: "14px",
    lineHeight: "17px",
    textAlign: 'center',
    paddingTop:'1rem'
  },
}))

export default CardTestimonials