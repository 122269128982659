import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from '@mui/material';
import facebook from '../../img/icon/facebook.png';
import instagram from '../../img/icon/instagram.png';
import twiter from '../../img/icon/twitter.png';

const Footer2 = () => {
  return (
    <footer className='footer-loftyapps'>
      <Container maxWidth='xl' className='padding-container-lofyapps'>
        <div style={{display: 'flex', justifyContent: 'center', margin: 25}}>
            <a href="https://www.facebook.com/profile.php?id=100092092552915" className='icon-redsocial-loftyapps '><img alt='Facebook' src={facebook}></img></a>
            <a href="https://www.instagram.com/loftyappslatam/" className='icon-redsocial-loftyapps '><img alt='Instagram' src={instagram}></img></a>
            <a href="https://twitter.com/loftyApps" className='icon-redsocial-loftyapps '><img alt='Twitter' src={twiter}></img></a>
        </div>
        <div style={{backgroundColor: '#2E4E73', height: 1}} />
        <div className='footer-text-loftyapps'>
          <Link to='/terms' className='text-white' >
            Términos y condiciones
          </Link>
          <Link to='/terms' className='text-white' >
            Politica de privacidad
          </Link>
          <Link to='/faq' className='text-white' >
            Preguntas frecuentes
          </Link>
          <p style={{margin: 0}} className='text-white'>
            Copyright 2023 | Todos los Derechos reservados
          </p>
        </div>
      </Container>
    </footer>
  );
}

export default Footer2;

