import { Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { makeStyles } from '@mui/styles';
import data from '../textdata/tc.json'

const TermsConditions = () => {
  const classes = useStyles();
  return (
    <>
      <Grid container spacing={2} className="pt-3">
        <Grid item xs={1} sm={2} ></Grid>
        <Grid item xs={9} sm={7} className="pt-3">
          <Box className={classes.textPTitle}>
            <Typography >
              <b className={classes.textTitle}>Terminos y Condiciones</b>
            </Typography>
            {/* Info Relevante*/}
            <Typography>
              <span className={classes.textTitle2}>
                {data.titleInfoRelevante}
              </span>
            </Typography>
            <Typography align='justify'>
              <p className={classes.textP}>
                <div dangerouslySetInnerHTML={{ __html: data.textInfoRelevante }} />
              </p>
              <p className={classes.textP}>
                <div dangerouslySetInnerHTML={{ __html: data.text2InfoRelevante }} />
              </p>
              <p className={classes.textP}>
                <div dangerouslySetInnerHTML={{ __html: data.text3InfoRelevante }} />
              </p>
              <p className={classes.textP}>
                <div dangerouslySetInnerHTML={{ __html: data.text4InfoRelevante }} />
              </p>
              <p className={classes.textP}>
                <div dangerouslySetInnerHTML={{ __html: data.text5InfoRelevante }} />
              </p>
              <p className={classes.textP}>
                <div dangerouslySetInnerHTML={{ __html: data.text6InfoRelevante }} />
              </p>
            </Typography>
            {/* Terminologia */}
            <Typography>
              <span className={classes.textTitle2}>
                {data.titleTerminologia}
              </span>
            </Typography>
            <Typography align='justify'>
              <ul>
                <div dangerouslySetInnerHTML={{ __html: data.list1Terminologia }} />
                <div dangerouslySetInnerHTML={{ __html: data.list2Terminologia }} />
                <div dangerouslySetInnerHTML={{ __html: data.list3Terminologia }} />
                <div dangerouslySetInnerHTML={{ __html: data.list4Terminologia }} />
                <div dangerouslySetInnerHTML={{ __html: data.list5Terminologia }} />
                <div dangerouslySetInnerHTML={{ __html: data.list6Terminologia }} />
              </ul>
            </Typography>
            <Typography>
              <span className={classes.textTitle2}>
                {data.title3Main}
              </span>
            </Typography>
            {/* Content User */}
            <Typography align='justify'>
              <p className={classes.textP}>
                <div dangerouslySetInnerHTML={{ __html: data.textContentMain }} />
              </p>
            </Typography>
            {/* Servicios */}
            <Typography>
              <span className={classes.textTitle2}>
                <b>
                  {data.titleServices}
                </b>
              </span>
            </Typography>
            <Typography align='justify'>
              <p className={classes.textP}>
                <div dangerouslySetInnerHTML={{ __html: data.textServices }} />
              </p>
            </Typography>
            {/* Licencia */}
            <Typography>
              <span className={classes.textTitle2}>
                <b>
                  {data.titleLicense}
                </b>
              </span>
            </Typography>
            <Typography align='justify'>
              <p className={classes.textP}>
                <div dangerouslySetInnerHTML={{ __html: data.text1License }} />
              </p>
              <p className={classes.textP}>
                <div dangerouslySetInnerHTML={{ __html: data.text2License }} />
              </p>
              <p className={classes.textP}>
                <div dangerouslySetInnerHTML={{ __html: data.text3License }} />
              </p>
              <p className={classes.textP}>
                <div dangerouslySetInnerHTML={{ __html: data.text4License }} />
              </p>
            </Typography>
            {/* Restricciones */}
            <Typography>
              <span className={classes.textTitle2}>
                <b>
                  {data.titleRestrictions}
                </b>
              </span>
            </Typography>
            <Typography align='justify'>
              <p className={classes.textP}>
                <div dangerouslySetInnerHTML={{ __html: data.text1Restrictions }} />
              </p>
              <p className={classes.textP}>
                <div dangerouslySetInnerHTML={{ __html: data.text2Restrictions }} />
              </p>
            </Typography>
            {/* Prestaciones */}
            <Typography>
              <span className={classes.textTitle2}>
                <b>
                  {data.titlePrestac}
                </b>
              </span>
            </Typography>
            <Typography align='justify'>
              <p className={classes.textP}>
                <div dangerouslySetInnerHTML={{ __html: data.textPrestac }} />
              </p>
            </Typography>
            {/* Use Services */}
            <Typography>
              <span className={classes.textTitle2}>
                <b>
                  {data.titleUseServices}
                </b>
              </span>
            </Typography>
            {/* User Use */}
            <Typography>
              <span className={classes.textTitle2}>
                <b>
                  {data.titleUseUser}
                </b>
              </span>
            </Typography>
            <Typography align='justify'>
              <p className={classes.textP}>
                <div dangerouslySetInnerHTML={{ __html: data.text1UseUser }} />
              </p>
              <p className={classes.textP}>
                <div dangerouslySetInnerHTML={{ __html: data.text2UseUser }} />
              </p>
            </Typography>
            {/* User Req */}
            <Typography>
              <span className={classes.textTitle2}>
                <b>
                  {data.titleReq}
                </b>
              </span>
            </Typography>
            <Typography align='justify'>
              <p className={classes.textP}>
                <div dangerouslySetInnerHTML={{ __html: data.text1Req }} />
              </p>
            </Typography>
            {/* user Content */}
            <Typography>
              <span className={classes.textTitle2}>
                <b>
                  {data.titleUserContent}
                </b>
              </span>
            </Typography>
            <Typography align='justify'>
              <p className={classes.textP}>
                <div dangerouslySetInnerHTML={{ __html: data.text1UserContent }} />
              </p>
              <p className={classes.textP}>
                <div dangerouslySetInnerHTML={{ __html: data.text2UserContent }} />
              </p>
              <p className={classes.textP}>
                <div dangerouslySetInnerHTML={{ __html: data.text3UserContent }} />
              </p>
              <p className={classes.textP}>
                <div dangerouslySetInnerHTML={{ __html: data.text4UserContent }} />
              </p>
            </Typography>
            {/* Network Access */}
            <Typography>
              <span className={classes.textTitle2}>
                <b>
                  {data.titleNetAccess}
                </b>
              </span>
            </Typography>
            <Typography align='justify'>
              <p className={classes.textP}>
                <div dangerouslySetInnerHTML={{ __html: data.text1NetAccess }} />
              </p>
            </Typography>
            {/* Subscription */}
            <Typography>
              <span className={classes.textTitle2}>
                <b>
                  {data.titleSubscription}
                </b>
              </span>
            </Typography>
            <Typography align='justify'>
              <p className={classes.textP}>
                <div dangerouslySetInnerHTML={{ __html: data.text1Subscription }} />
              </p>
              <ul>
                <div dangerouslySetInnerHTML={{ __html: data.list1Subscription }} />
                <div dangerouslySetInnerHTML={{ __html: data.list2Subscription }} />
                <div dangerouslySetInnerHTML={{ __html: data.list3Subscription }} />
              </ul>
            </Typography>
            {/* Export Code */}
            <Typography>
              <span className={classes.textTitle2}>
                <b>
                  {data.titleExportCode}
                </b>
              </span>
            </Typography>
            <Typography align='justify'>
              <p className={classes.textP}>
                <div dangerouslySetInnerHTML={{ __html: data.text1ExportCode }} />
              </p>
              <ul>
                <div dangerouslySetInnerHTML={{ __html: data.list1ExportCode }} />
                <div dangerouslySetInnerHTML={{ __html: data.list2ExportCode }} />
                <div dangerouslySetInnerHTML={{ __html: data.list3ExportCode }} />
                <div dangerouslySetInnerHTML={{ __html: data.list4ExportCode }} />
              </ul>
            </Typography>
            {/* Deployment */}
            <Typography>
              <span className={classes.textTitle2}>
                <b>
                  {data.titleDeploySubscription}
                </b>
              </span>
            </Typography>
            <Typography align='justify'>
              <p className={classes.textP}>
                <div dangerouslySetInnerHTML={{ __html: data.text1DeploySubscription }} />
              </p>
              <ul>
                <li><div dangerouslySetInnerHTML={{ __html: data.list1Deploy }} /></li>
                <li><div dangerouslySetInnerHTML={{ __html: data.list2Deploy }} /></li>
                <li><div dangerouslySetInnerHTML={{ __html: data.list3Deploy }} /></li>
              </ul>
            </Typography>
            {/* Pago */}
            <Typography>
              <span className={classes.textTitle2}>
                <b>
                  {data.titlePasarela}
                </b>
              </span>
            </Typography>
            <Typography align='justify'>
              <p className={classes.textP}>
                <div dangerouslySetInnerHTML={{ __html: data.text1Pasarela }} />
              </p>
            </Typography>
            {/* Pago */}
            <Typography>
              <span className={classes.textTitle2}>
                <b>
                  {data.titlePago}
                </b>
              </span>
            </Typography>
            <Typography align='justify'>
              <p className={classes.textP}>
                <div dangerouslySetInnerHTML={{ __html: data.text1Pago }} />
              </p>
              <p className={classes.textP}>
                <div dangerouslySetInnerHTML={{ __html: data.text2Pago }} />
              </p>
              <p className={classes.textP}>
                <div dangerouslySetInnerHTML={{ __html: data.text3Pago }} />
              </p>
            </Typography>
            {/* Gestion */}
            <Typography>
              <span className={classes.textTitle2}>
                <b>
                  {data.titleGestion}
                </b>
              </span>
            </Typography>
            <Typography align='justify'>
              <p className={classes.textP}>
                <div dangerouslySetInnerHTML={{ __html: data.text1Gestion }} />
              </p>
            </Typography>
            {/* RENUNCIAS */}
            <Typography>
              <span className={classes.textTitle2}>
                <b>
                  {data.mainRenuncias}
                </b>
              </span>
            </Typography>
            {/* Renuncia */}
            <Typography>
              <span className={classes.textTitle2}>
                <b>
                  {data.titleRenuncias}
                </b>
              </span>
            </Typography>
            <Typography align='justify'>
              <p className={classes.textP}>
                <div dangerouslySetInnerHTML={{ __html: data.text1Renuncias }} />
              </p>
            </Typography>
            {/*Limitaciones*/}
            <Typography>
              <span className={classes.textTitle2}>
                <b>
                  {data.titleLimitations}
                </b>
              </span>
            </Typography>
            <Typography align='justify'>
              <p className={classes.textP}>
                <div dangerouslySetInnerHTML={{ __html: data.text1Limitations }} />
              </p>
              <p className={classes.textP}>
                <div dangerouslySetInnerHTML={{ __html: data.text2Limitations }} />
              </p>
            </Typography>
            {/*Indemnidad*/}
            <Typography>
              <span className={classes.textTitle2}>
                <b>
                  {data.titleIndemnidad}
                </b>
              </span>
            </Typography>
            <Typography align='justify'>
              <p className={classes.textP}>
                <div dangerouslySetInnerHTML={{ __html: data.text1Indemnidad }} />
              </p>
            </Typography>
            {/*Legislacion*/}
            <Typography>
              <span className={classes.textTitle2}>
                <b>
                  {data.titleLegislacion}
                </b>
              </span>
            </Typography>
            <Typography align='justify'>
              <p className={classes.textP}>
                <div dangerouslySetInnerHTML={{ __html: data.text1Legislacion }} />
              </p>
              <p className={classes.textP}>
                <div dangerouslySetInnerHTML={{ __html: data.text2Legislacion }} />
              </p>
            </Typography>
            {/*Notificaciones*/}
            <Typography>
              <span className={classes.textTitle2}>
                <b>
                  {data.titleNotifications}
                </b>
              </span>
            </Typography>
            <Typography align='justify'>
              <p className={classes.textP}>
                <div dangerouslySetInnerHTML={{ __html: data.text1Notifications }} />
              </p>
            </Typography>
            {/*Disposiciones*/}
            <Typography>
              <span className={classes.textTitle2}>
                <b>
                  {data.titleDisposiciones}
                </b>
              </span>
            </Typography>
            <Typography align='justify'>
              <p className={classes.textP}>
                <div dangerouslySetInnerHTML={{ __html: data.text1Disposiciones }} />
              </p>
              <p className={classes.textP}>
                <div dangerouslySetInnerHTML={{ __html: data.text2Disposiciones }} />
              </p>
            </Typography>
            {/* Warning */}
            <Typography align='justify'>
              <p className={classes.textP}>
                <div dangerouslySetInnerHTML={{ __html: data.warningTextTERMS }} />
              </p>
            </Typography>

          </Box>
        </Grid>
      </Grid>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  section: {
    minHeight: "100vh",
  },
  textPTitle: {
    width: '100%',
    paddingTop: '2rem',
    [theme.breakpoints.down("sm")]: {
      paddingTop: '0rem',
    },
  },
  sectioncontent: {
    maxWidth: "80Vh",
    margin: "0 auto",
  },
  textTitle: {
    fontFamily: 'TimesNewRomanPS-BoldMT',
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "3em",
    lineHeight: "75px",
    letterSpacing: "0em",
    paddingRight: "1rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: '1.5em',
      paddingLeft: '0.5rem'
    },
  },
  textTitle2: {
    fontFamily: 'TimesNewRomanPS-BoldMT',
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "1.1em",
    lineHeight: "55px",
    letterSpacing: "0em",
    [theme.breakpoints.down("sm")]: {
      fontSize: '1em',
      padding: '0rem',
    },
  },
  textP: {
    fontFamily: 'TimesNewRomanPSMT',
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "21px",
    letterSpacing: "0em",
    padding: "1rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: '0.9em',
      padding: '0rem',
      margin: '0rem',
      paddingLeft: '0.5rem'
    },
  },
}))

export default TermsConditions;