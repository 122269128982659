import React from 'react';
import {Grid} from '@mui/material';
import Layout from '../layouts/Layout';
import coverImage from '../img/blogs/Image.png';
import img1 from '../img/blogs/placeholder1.jpg';
import img2 from '../img/blogs/placeholder2.jpg';
import img3 from '../img/blogs/placeholder3.png';
import BlogCard from '../components/blogComponents/blogCard';
import '../styles/blogs.css';

const blogs = [
  {
    image: img1,
    title: 'The Impact of Technology on the Workplace: How Technology is Changing',
    type: 'Technology',
    info: 'N/A',
  },
  {
    image: img2,
    title: 'The Impact of Technology on the Workplace: How Technology is Changing',
    type: 'Technology',
    info: 'N/A',
  },
  {
    image: img3,
    title: 'The Impact of Technology on the Workplace: How Technology is Changing',
    type: 'Technology',
    info: 'N/A',
  },
  {
    image: img1,
    title: 'The Impact of Technology on the Workplace: How Technology is Changing',
    type: 'Technology',
    info: 'N/A',
  },
  {
    image: img2,
    title: 'The Impact of Technology on the Workplace: How Technology is Changing',
    type: 'Technology',
    info: 'N/A',
  },
  {
    image: img3,
    title: 'The Impact of Technology on the Workplace: How Technology is Changing',
    type: 'Technology',
    info: 'N/A',
  },
  {
    image: img1,
    title: 'The Impact of Technology on the Workplace: How Technology is Changing',
    type: 'Technology',
    info: 'N/A',
  },
  {
    image: img2,
    title: 'The Impact of Technology on the Workplace: How Technology is Changing',
    type: 'Technology',
    info: 'N/A',
  },
  {
    image: img3,
    title: 'The Impact of Technology on the Workplace: How Technology is Changing',
    type: 'Technology',
    info: 'N/A',
  },
];

const BlogsPage = ({ history }) => {
  const sampleText = 'The Impact of Technology on the Workplace: How Technology is Changing';
  const handleClick = () => {
    console.log('Effect');
  }
  return (
    <Layout>
      <header className='header-price-plan-landing-style'>
        <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <div
          className='picture-style-background'
          style={{
            width: '95%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
            marginTop: '20px',
            marginBottom: '20px',
            backgroundImage: `url(${coverImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            borderRadius: '12px',
          }}
        >
          <div className='block-title-area'>
            <div
              style={{
                display: 'flex',
                padding: '4px 10px',
                justifyContent: 'center',
                alignItems: 'center',
                color: '#fff',
                backgroundColor: 'rgba(75, 107, 251, 1)',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '20px',
                borderRadius: '6px',
                marginBottom: '10px',
              }}
            >
              Technology
            </div>
            <div className='title-block-cover-style'>
              {sampleText}
            </div>
          </div>
        </div>
        </div>
      </header>
      <div style={{marginTop: '100px', padding: '2rem', marginBottom: '100px'}}>
        <Grid container spacing={2}>
          {
            blogs.map((index) => (
              <Grid item md={4} sm={12}>
                <BlogCard
                  image={index.image}
                  type={index.type}
                  title={index.title}
                  onClick={() => handleClick()}
                />
              </Grid>
            ))
          }
        </Grid>
      </div>
    </Layout>
  );
}

export default BlogsPage;