import React from 'react';
import {Grid} from '@mui/material';
import Layout from '../layouts/Layout';
import coverImage from '../img/blogs/blogInfo_Placeholder1.jpg';
import placeholder2 from '../img/blogs/blogInfo_Placeholder2.jpg';
import img1 from '../img/blogs/articles.png';
import '../styles/blogs.css';
import ArticleCard from '../components/blogComponents/articleCard';

const blogs = [
  {
    image: img1,
    title: 'Lorem ipsum dolor sit amet consectetur. Ut mi volutpat sapien in aliquam blandit libero vestibulum. ',
    description: 'Lorem ipsum dolor sit amet consectetur. Fringilla duis sed enim feugiat risus tincidunt. Tortor neque rutrum adipiscing proin feugiat.',
    date: '2023 November',
  },
  {
    image: img1,
    title: 'Lorem ipsum dolor sit amet consectetur. Ut mi volutpat sapien in aliquam blandit libero vestibulum. ',
    description: 'Lorem ipsum dolor sit amet consectetur. Fringilla duis sed enim feugiat risus tincidunt. Tortor neque rutrum adipiscing proin feugiat.',
    date: '2023 November',
  },
  {
    image: img1,
    title: 'Lorem ipsum dolor sit amet consectetur. Ut mi volutpat sapien in aliquam blandit libero vestibulum. ',
    description: 'Lorem ipsum dolor sit amet consectetur. Fringilla duis sed enim feugiat risus tincidunt. Tortor neque rutrum adipiscing proin feugiat.',
    date: '2023 November',
  },
];

const BlogInfoPage = ({ history }) => {
  const sampleText = `Lorem ipsum dolor sit amet consectetur. Commodo pulvinar nibh lectus diam adipiscing. 
  Non fringilla lacinia ornare lorem eleifend posuere. Magna leo pellentesque elementum vehicula volutpat volutpat aliquam. 
  Lorem ipsum dolor sit amet consectetur. Commodo pulvinar nibh lectus diam adipiscing.
  Non fringilla lacinia ornare lorem eleifend posuere. Magna leo pellentesque elementum vehicula volutpat volutpat aliquam.`;
  const handleClick = () => {
    console.log('Effect');
  }
  return (
    <Layout>
      <header className='header-price-plan-landing-style'>
      <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginTop: '20px',
            marginBottom: '50px',
          }}
        >
          <div className='title-blog-info-main'>
          Lorem ipsum dolor sit amet consectetur
          </div>
          <div className='subtitle-blog-info-main'>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Scelerisque ut id congue commodo. Semper malesuada morbi adipiscing tellus posuere. Interdum.
          </div>
        </div>
        <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <img
          className='blog-info-cover-style-background'
          src={coverImage}
          alt=""
        />
        </div>
      </header>
      <div style={{marginTop: '20px', padding: '2rem', marginBottom: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <div className='container-info-blog'>
          <div className='paragraph-info-blog'>
            {sampleText}
          </div>
          <div className='paragraph-info-blog'>
            {sampleText}
          </div>
          <img className='image-info-blog' src={placeholder2} alt=""/>
          <div className='divisor-info-blog'>
            <div className='bar-divisor-info-blog' />
            <div className='text-divisor-info-blog'>
              Lorem ipsum dolor sit amet consectetur. Quisque pharetra est blandit ut ullamcorper. Pellentesque hac eget commodo varius.
            </div>
          </div>
          <div className='paragraph-info-blog'>
            {sampleText}
          </div>
          <div className='paragraph-info-blog'>
            {sampleText}
          </div>
        </div>
      </div>
      <div
        style={{
          marginTop: '20px',
          padding: '4rem 2rem',
          marginBottom: '20px',
          backgroundColor: '#F2F4F7',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Grid container className='grid-articles-style'>
          {
            blogs.map((index) => (
              <Grid item xl={12}>
                <ArticleCard
                  image={index.image}
                  date={index.date}
                  description={index.description}
                  title={index.title}
                  onClick={() => handleClick()}
                />
              </Grid>
            ))
          }
        </Grid>
      </div>
    </Layout>
  );
}

export default BlogInfoPage;