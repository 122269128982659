import { Button, Typography, Box, Hidden} from '@mui/material';
import React from 'react';
// import work from "../img/work.png";
import { makeStyles } from '@mui/styles';

const Schedule = ({ handleButton }) => {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.mainGrid}>
          <Box className={classes.textPTitle}>
            <Typography >
              <Box className={classes.textTitle}>
              ¿Te imaginas la herramienta de software perfecta para tu empresa pero no sabes codificar?
              </Box>
              <Box className={classes.textTitle2}>
                <br />
                Todo esto y más lo puedes hacer con LoftyApps, sin necesidad de una línea de código.
              </Box>
            </Typography>
            <Hidden smDown>
              <Button
                onClick={handleButton} variant="outlined"
                size="large"
                className={classes.btnInfo1}
                sx={{
                  color: 'rgba(255,255,255,1)',
                  border: '.1rem solid rgba(255,255,255,1)',
                }}
              >
                AGENDAR REUNIÓN
              </Button>
            </Hidden>
          </Box>
      </Box>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  mainGrid: {
    height: '100%',
    width: '100%',
    [theme.breakpoints.down("md")]: {
      height: 'auto',
    },
    [theme.breakpoints.down("sm")]: {
      height: '350px',
    },
    [theme.breakpoints.down("450")]: {
      height: '300px',
    },
    [theme.breakpoints.down("400")]: {
      height: '250px',
    },
    [theme.breakpoints.down("360")]: {
      height: '225px',
    },
  },
  section: {
    minHeight: "100vh",
  },
  textPTitle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    width: '60%',
    height: '100%',
    paddingTop: '2rem',
    paddingLeft: '3rem',
    paddingRight: '10px',
    [theme.breakpoints.down("md")]: {
      paddingTop: '1rem',
      height: '60%',
    },
    [theme.breakpoints.down("sm")]: {
      height: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
    },
    [theme.breakpoints.down("500")]: {
      width: '70%',
    },
    [theme.breakpoints.down("400")]: {
      paddingLeft: '10px',
      width: '90%',
    },
  },
  sectioncontent: {
    maxWidth: "80Vh",
    margin: "0 auto",
  },
  textTitle: {
    fontFamily: 'Roboto',
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "2rem",
    lineHeight: "1.9rem",
    letterSpacing: "0em",
    // paddingRight: "1rem",
    [theme.breakpoints.down("lg")]: {
      fontSize: '38px',
      lineHeight: "3.5rem",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: '30px',
      lineHeight: "2.5rem",
    },
    [theme.breakpoints.down("850")]: {
      fontSize: '24px',
      // lineHeight: "2.5rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: '14px',
      lineHeight: "1.5rem",
    },
    [theme.breakpoints.down("400")]: {
      lineHeight: "1.2rem",
    },
  },
  textTitle2: {
    fontFamily: 'Roboto',
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "1.9rem",
    letterSpacing: "0em",
    marginBottom: '2rem',
    [theme.breakpoints.down("850")]: {
      fontSize: '14px',
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: '11px',
      padding: '0rem',
    },
  },
  textP: {
    fontFamily: 'Roboto',
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "21px",
    letterSpacing: "0em",
    padding: "1rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: '0.9em',
      padding: '0rem',
      margin: '0rem',
      paddingLeft: '0.5rem'
    },
  },
  btnInfo1: {
    width: "20rem",
    height: "fit-content",
    borderRadius: "5px",
    fontSize: '1.2rem !important',
    marginTop: "4rem !important",
    [theme.breakpoints.down("sm")]: {
      width: "120px",
      fontSize: '0.5em !important',
      marginTop: "1.5rem !important",
      marginLeft: '0.8rem!important'
    },
  },
}))

export default Schedule