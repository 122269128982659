import { Button, Card, CardContent } from '@mui/material'
import React from 'react'
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import './styleCard.css';

// const Item = ({ itemName, items, classStyle }) => {
//   const classes = useStyles();
//   return (
//     <Box>
//       <Box className={classStyle}>
//         <CheckCircleIcon/> {itemName}
//       </Box>
//       <ul>
//         {items?.map(item => (
//           <li key={`${itemName} ${item.value}`}>
//             <Box className={classes.boxZero}>
//               <Box className={classes.boxOne}>
//                 {item.name}
//               </Box>
//               <Box className={classes.boxTwo}>
//                 <span>
//                   {item.value}
//                 </span>
//               </Box>
//             </Box>
//           </li>
//         ))}
//       </ul>
//     </Box>
//   )
// }
const DottedLine = () => {

  return (
    <Box><hr style={{ border: "1px dotted #29B2EF", minWidth: "100px" }} /></Box>
  )
}


const CardPriceDeployTablet = ({ type,
  priceMonth,
  priceYear,
  appweb,
  backoffice,
  database,
  deployment,
  collabs,
  storage,
  dataTest,
  verPage,
  onClick }) => {
  const classes = useStyles();
  return (
    <Card className={classes.card1} style={{ marginTop: '1rem'}}>
      <CardContent className={`${classes.colorCardW1}   ${classes.cardTam1}`}>
        <Box className={classes.boxContainerTitle}>
          <Box className={classes.boxContainerTwo1}>
            <p className={classes.itemTitle1}>
              {type}
            </p>
            <Box className={classes.boxPriceContainer}>
              <p className={classes.priceCardWhite1}>L{priceMonth}/Mes</p>
              <DottedLine />
              <p className={classes.priceCardWhite1}>L{priceYear}/Anual</p>
            </Box>
          </Box>
          <Box className={`${classes.recomienda1} ${classes.boxBtn1}`}>
            <Button onClick={onClick} variant="contained" size="small" className={classes.btnInfo1}>OBTENER INFORMACIÓN</Button>
          </Box>
        </Box>

        
        <Box className={classes.boxContainer1}>
        <Box classname={classes.boxTree1}>
          <Box className={classes.itemBoxContainer}>
            <p>Colaboradores</p>
            <p>{collabs}</p>
          </Box>
          <Box className={classes.itemBoxContainer}>
            <p>Almacenamiento </p>
            <p>{storage}</p>
          </Box>
          {/* <Box className={classes.itemBoxContainer}>
            <p>Data Test</p>
            <p>{dataTest}</p>
          </Box>
          <Box className={classes.itemBoxContainer} >
            <p>Versiones de Páginas</p>
            <p style={{marginLeft: '15px'}}>{verPage}</p>
          </Box>
          <Item itemName='App Web' classStyle={classes.itemComponentTitle}
            items={appweb}/>
          <Item itemName='Backoffice' classStyle={classes.itemComponentTitle}
            items={backoffice} /> */}
        </Box>
        {/* <Box classname={classes.boxTree1}>
          <Item itemName='Base de datos' classStyle={classes.itemComponentTitle}
            items={database} />
          <Box className={classes.itemDeployContainer}>
            <Box className={classes.itemDeployTextArea}>
              <CheckCircleIcon />Despliegue
            </Box> 
            {deployment}
          </Box>
        </Box> */}
        </Box>
      </CardContent>
    </Card >
  )
}

const useStyles = makeStyles((theme) => ({
  card1: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    width: "70vw",
    height: "auto",
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    color: "#201549",
    boxShadow: '0px 4px 6px #1345A6!important',
    borderRadius: '5px!important',
  },
  itemTitle1: {
    fontSize: '36px',
    fontWeight: '700',
    lineHeight: '42px',
    letterSpacing: '0em',
    textAlign: 'left',
    color: '#fff',
  }
  ,
  purpleCard1: {
    backgroundColor: "#201549",
    height: "600px",
    color: '#FFF',
    boxShadow: '0px 4px 6px #1345A6!important',
    borderRadius: '5px!important',
  },
  boxTwo1: {
    display: 'flex',
    alignItems: 'rigth',
    flexDirection: 'row',
    justifyContent: "flex-end",
    paddingLeft: "2rem"
  }, boxOne1: {
    display: 'flex',
    alignItems: 'left',
    flexDirection: 'row'
  }, boxZero1: {
    paddingTop: '0.5rem',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row'
  },
  colorCardW1: {
    color: "#201549",
  },
  cardTam1: {
    width: '100%',
    padding: '2rem'
  },
  boxTree1: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  priceCardPurple1: {
    fontSize: "30px",
    lineHeight: "35px",
    color: "#FFFFFF"

  },
  priceCardWhite1: {
    fontSize: "18px",
    lineHeight: "15px",
    margin: '5px',
    color: "#fff"
  },
  btnInfo1: {
    width: "195px",
    height: "30px",
    borderRadius: "5px",
    fontSize: "13px",
    lineHeight: "15px",
  },
  boxBtn1: {
    paddingTop: '2rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  showBtn: {
    display: "none"
  },
  hiddenBtn: {
    display: "show"
  },
  recomienda1: {
    padding: "0px",
    margin: "0px",
    fontSize: "20px!important",
    lineHeight: "23px",
  },
  boxContainer1: {
    margin: '5px',
  },
  boxContainerTitle: {
    backgroundColor: '#201549',
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'space-around',
  },
  boxContainerTwo1: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
  },
  boxPriceContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '10px',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
  },
  itemBoxContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
  },
  itemDeployContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  itemDeployTextArea: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    fontWeight: 'bold',
  },
  itemComponentTitle: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    fontWeight: 'bold',
  },
}))

export default CardPriceDeployTablet