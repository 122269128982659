import { Button, Card, CardContent } from '@mui/material'
import React from 'react'
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';

// const Item = ({ itemName, items, classStyle }) => {
//   const classes = useStyles();
//   return (
//     <Box>
//       <Box className={classStyle}>
//         <CheckCircleIcon/> {itemName}
//       </Box>
//       <ul>
//         {items?.map(item => (
//           <li key={`${itemName} ${item.value}`}>
//             <Box className={classes.boxZero}>
//               <Box className={classes.boxOne}>
//                 {item.name}
//               </Box>
//               <Box className={classes.boxTwo}>
//                 <span>
//                   {item.value}
//                 </span>
//               </Box>
//             </Box>
//           </li>
//         ))}
//       </ul>
//     </Box>
//   )
// }

const DottedLine = () => {
  return (
    <Box><hr style={{border:"1px dotted #29B2EF", minWidth:"100px"}} /></Box>
  )
}

const CardPlanDeployPrice = ({
  type,
  priceMonth,
  priceYear,
  appweb,
  backoffice,
  database,
  deployment,
  collabs,
  storage,
  dataTest,
  verPage,
  onClick
}) => {
  const classes = useStyles();
  return (
    <Card sx={{ minWidth: 250 }} className={ classes.card}>
      <CardContent className={ classes.colorCardW}>
        <Box className={classes.containerTitle}>
          <p className={classes.itemTitle}>
            {type}
          </p>
          <p className={classes.priceCardWhite}>L{priceMonth}/Mes</p>
          <DottedLine />
          <p className={ classes.priceCardWhite} style={{marginBottom: '20px'}}>L{priceYear}/Anual</p>
        </Box>
        <Box classname={classes.boxTree}>
          <Box className={classes.itemBoxContainer}>
            <p>Colaboradores</p>
            <p>{collabs}</p>
          </Box>
          <Box className={classes.itemBoxContainer}>
            <p>Almacenamiento</p>
            <p>{storage}</p>
          </Box>
          {/* <Box className={classes.itemBoxContainer}>
            <p>Data Test</p>
            <p>{dataTest}</p>
          </Box>
          <Box className={classes.itemBoxContainer}>
            <p>Versiones de Páginas</p>
            <p>{verPage}</p>
          </Box>
          <Item itemName='App Web' classStyle={classes.itemComponentTitle}
            items={appweb}/>
          <Item itemName='Backoffice' classStyle={classes.itemComponentTitle}
            items={backoffice} />
          <Item itemName='Base de datos' classStyle={classes.itemComponentTitle}
            items={database} />
          <Box className={classes.itemDeployContainer}>
            <Box className={classes.itemDeployTextArea}>
              <CheckCircleIcon />Despliegue
            </Box> 
            {deployment}
          </Box> */}
        </Box>
        <Box className={classes.boxBtn}>
          <Button onClick={onClick} variant="contained" size="small" className={classes.btnInfo}>OBTENER INFORMACIÓN</Button>
        </Box>
      </CardContent>
    </Card >
  )
}

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    width: "280px",
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    color: "#201549",
    // boxShadow: '0px 4px 6px #1345A6!important',
    borderRadius: '5px!important',
    border: '1px solid rgba(19, 69, 166, 1)',
  },
  itemTitle: {
    fontSize: '24px',
    fontWeight: '700',
    lineHeight: '42px',
    letterSpacing: '0em',
    textAlign: 'left',
    paddingTop: '1rem',
    color: '#201549',
  }
  ,
  boxTwo: {
    display: 'flex',
    alignItems: 'rigth',
    flexDirection: 'row',
    justifyContent: "flex-end",
    paddingLeft: "2rem"
  }, boxOne: {
    display: 'flex',
    alignItems: 'left',
    flexDirection: 'row'
  }, boxZero: {
    paddingTop: '0.5rem',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row'
  },
  colorCardW: {
    color: "#201549",
  },
  boxTree: {
    paddingTop: '1rem',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: "2rem"
  },
  priceCardWhite: {
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "15px",
    margin: '5px',
    color: "#201549"
  },
  btnInfo:{
    width: "195px",
    height: "30px",
    borderRadius: "5px",
    fontSize: "13px",
    lineHeight: "15px",
  },
  boxBtn: {
    paddingTop: '2rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems:'center'
  },
  showBtn:{
    display:"none"
  },
  hiddenBtn:{
    display:"show"
  },
  recomienda:{
    padding:"0px",
    margin:"0px",
    fontSize: "20px!important",
    lineHeight: "23px",
  },
  containerTitle: {
    width: '250px',
    marginBottom: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  itemBoxContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  itemComponentTitle: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    fontWeight: 'bold',
  },
  itemDeployContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  itemDeployTextArea: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    fontWeight: 'bold',
  },
}))

export default CardPlanDeployPrice;
