import { Box } from '@mui/material'
import React from 'react'
import { makeStyles } from '@mui/styles';


const CardAdvantages = ({ adventage, description, icon }) => {
  const classes = useStyles();
  return (
    <Box className={classes.card}>
      <Box component="div" className={classes.cardInfoContainer}>
        <Box component="img" src={icon} alt="text" className={classes.imgWidth} />
        <Box component="div" className={classes.innerCard}>
          <p className={classes.cardPadding}>
            {adventage}
          </p>
          <p className={classes.textTitle}>{description}</p>
        </Box>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles(() => ({
  imgWidth: {
    width: '100%',
    height: 'auto',
    objectFit: 'contain',
  },
  card: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    width: '100%',
    fontFamily: 'Roboto',
    fontStyle: "normal",
    textAlign: "center",
    padding: "1rem",
    boxSizing: "border-box",
    // boxShadow: " 0px 4px 40px #EAE5E5!important",
    borderRadius: "5px!important",
    color: "#201549!important",
    backgroundColor: '#fff',
  },
  cardInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  innerCard: {
    top: '-10px',
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    width: '90%',
    backgroundColor: '#fff',
    zIndex: 1,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  },
  cardPadding: {
    paddingTop: '1rem',
    fontWeight: "700!important",
    fontSize: "2rem",
    lineHeight: "2rem",
    fontStyle: "Bold!important"
  },
  textTitle: {
    fontWeight: 300,
    fontSize: "1.2rem",
    lineHeight: "1.2",
    paddingTop: '1rem',
    paddingLeft: '2rem',
    paddingRight: '2rem'
  },
  imgCircle: {
    width: " 4.5rem",
    height: " 4.5rem",
    border: "1px solid #201549",
    borderRadius: "50px",
    display: 'flex',
    alignItems: 'center',
  }
}))

export default CardAdvantages