import { Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { makeStyles } from '@mui/styles';
import data from '../textdata/tc.json'

const PrivacyPolitics = () => {
  const classes = useStyles();
  return(<>
    <Grid container spacing={2} className="pt-3">
      <Grid item xs={1} sm={2} ></Grid>
      <Grid item xs={9} sm={7} className="pt-3">
        <Box className={classes.textPTitle}>
          <Typography >
            <b className={classes.textTitle}>POLÍTICA DE PRIVACIDAD</b>
          </Typography>

          {/*Privacidad*/}
          <Typography align='justify'>
            <p className={classes.textP}>
              <div dangerouslySetInnerHTML={{ __html: data.text1Privacy }} />
            </p>
          </Typography>
          {/*Chosen Info*/}
          <Typography>
            <span className={classes.textTitle2}>
              <b>
                {data.titleNotChosen}
              </b>
            </span>
          </Typography>
          <Typography align='justify'>
            <p className={classes.textP}>
              <div dangerouslySetInnerHTML={{ __html: data.text1NotChosen }} />
            </p>
            <p className={classes.textP}>
              <div dangerouslySetInnerHTML={{ __html: data.text2NotChosen }} />
            </p>
          </Typography>
          {/*Info Use*/}
          <Typography>
            <span className={classes.textTitle2}>
              <b>
                {data.titleInfoUse}
              </b>
            </span>
          </Typography>
          <Typography align='justify'>
            <p className={classes.textP}>
              <div dangerouslySetInnerHTML={{ __html: data.text1InfoUse }} />
            </p>
            <p className={classes.textP}>
              <div dangerouslySetInnerHTML={{ __html: data.text2InfoUse }} />
            </p>
            <p className={classes.textP}>
              <div dangerouslySetInnerHTML={{ __html: data.text3InfoUse }} />
            </p>
            <p className={classes.textP}>
              <div dangerouslySetInnerHTML={{ __html: data.text4InfoUse }} />
            </p>
            <p className={classes.textP}>
              <div dangerouslySetInnerHTML={{ __html: data.text5InfoUse }} />
            </p>
            <p className={classes.textP}>
              <div dangerouslySetInnerHTML={{ __html: data.text6InfoUse }} />
            </p>
            <p className={classes.textP}>
              <div dangerouslySetInnerHTML={{ __html: data.text7InfoUse }} />
            </p>
            <p className={classes.textP}>
              <div dangerouslySetInnerHTML={{ __html: data.text8InfoUse }} />
            </p>
            <p className={classes.textP}>
              <div dangerouslySetInnerHTML={{ __html: data.text9InfoUse }} />
            </p>
          </Typography>
          {/*Third Party*/}
          <Typography>
            <span className={classes.textTitle2}>
              <b>
                {data.titleThirdParty}
              </b>
            </span>
          </Typography>
          <Typography align='justify'>
            <p className={classes.textP}>
              <div dangerouslySetInnerHTML={{ __html: data.text1ThirdParty }} />
            </p>
          </Typography>
          {/*Control your info*/}
          <Typography>
            <span className={classes.textTitle2}>
              <b>
                {data.titleControlInfo}
              </b>
            </span>
          </Typography>
          <Typography align='justify'>
            <p className={classes.textP}>
              <div dangerouslySetInnerHTML={{ __html: data.text1ControlInfo }} />
            </p>
            <p className={classes.textP}>
              <div dangerouslySetInnerHTML={{ __html: data.text2ControlerInfo }} />
            </p>
          </Typography>
          {/*Legislacion Final*/}
          <Typography>
            <span className={classes.textTitle2}>
              <b>
                {data.titleLegisl}
              </b>
            </span>
          </Typography>
          <Typography align='justify'>
            <p className={classes.textP}>
              <div dangerouslySetInnerHTML={{ __html: data.text1Legisl }} />
            </p>
          </Typography>
          {/*Disposicion Final*/}
          <Typography>
            <span className={classes.textTitle2}>
              <b>
                {data.titleFinal}
              </b>
            </span>
          </Typography>
          <Typography align='justify'>
            <p className={classes.textP}>
              <div dangerouslySetInnerHTML={{ __html: data.textFinal }} />
            </p>
          </Typography>
          {/* Warning Final */}
          <Typography align='justify'>
            <p className={classes.textP}>
              <div dangerouslySetInnerHTML={{ __html: data.warningFinal }} />
            </p>
          </Typography>
        </Box>
      </Grid>
    </Grid>
  </>
)
}
const useStyles = makeStyles((theme) => ({
  section: {
    minHeight: "100vh",
  },
  textPTitle: {
    width: '100%',
    paddingTop: '2rem',
    [theme.breakpoints.down("sm")]: {
      paddingTop: '0rem',
    },
  },
  sectioncontent: {
    maxWidth: "80Vh",
    margin: "0 auto",
  },
  textTitle: {
    fontFamily: 'TimesNewRomanPS-BoldMT',
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "3em",
    lineHeight: "75px",
    letterSpacing: "0em",
    paddingRight: "1rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: '1.5em',
      paddingLeft: '0.5rem'
    },
  },
  textTitle2: {
    fontFamily: 'TimesNewRomanPS-BoldMT',
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "1.1em",
    lineHeight: "55px",
    letterSpacing: "0em",
    [theme.breakpoints.down("sm")]: {
      fontSize: '1em',
      padding: '0rem',
    },
  },
  textP: {
    fontFamily: 'TimesNewRomanPSMT',
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "21px",
    letterSpacing: "0em",
    padding: "1rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: '0.9em',
      padding: '0rem',
      margin: '0rem',
      paddingLeft: '0.5rem'
    },
  },
}))

export default PrivacyPolitics;