import React, { useState } from 'react';
import { Button, Box, Hidden } from '@mui/material';
import { PopupModal } from "react-calendly";
import Navbar from '../components/Navbar/Navbar';
import items from '../components/Navbar/items';
import Schedule from './Schedule';
import Advantages from "./Advantages";
import { makeStyles } from '@mui/styles';
import fondo from "../img/bgImage.png";
import fondoMovil from "../img/bgImageMobile.png";
import bgColorImg from '../img/Union.png';
import bgColorMobile from '../img/Union_Mobile.png';
import Testimonials from './Testimonials';
// import ExportCode from './ExportCode';
import Footer from '../components/Footer/Footer';
import Contact from './Contact';
import PriceDeploy from './PriceDeploy';
import HashRedirect from '../components/HashRedirect';
import AfterInitSection from '../components/AfterInitSection';


const Home = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  return (
    <div>
      <div>
        <Navbar items={items} />
        <div className={classes.initContainer}>
          <div className={classes.sectiondark}>
            <div id="schedule" />
            <Schedule
              handleButton={() => setOpen(true)}
            />
          </div>
          <Hidden smUp>
            <Box className={classes.buttonContainer}>
              <Button
                onClick={() => setOpen(true)} variant="outlined"
                size="large"
                className={classes.btnInfo1}
                sx={{
                  color: 'rgba(41, 33, 81, 1)',
                  border: '1px solid rgba(41, 33, 81, 1)',
                }}
              >
                AGENDAR REUNIÓN
              </Button>
            </Box>
          </Hidden>
        </div>
      </div>
      <AfterInitSection />
      <div id="advantages" >
      </div>
      <Advantages />
      <div id="pricing" />
      {/* <PricePlan handleButtons={() => setOpen(true)} /> */}
      <PriceDeploy handleButtons={() => setOpen(true)} />
      {/* <ExportCode handleButtons={() => setOpen(true)} /> */}
      <div id="testimonials" />
      <Testimonials />
      <div id="contactUs" />
      <Contact />
      <Footer />
      <PopupModal
        url="https://calendly.com/argesware"
        onModalClose={() => setOpen(false)}
        open={open}
        /*
          * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
          * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
          */
        rootElement={document.getElementById("root")}
      />
      <HashRedirect />
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  btnInfo1: {
    width: "180px",
    height: "fit-content",
    borderRadius: "5px",
    fontSize: '12px !important',
    marginTop: "1rem !important",

  },
  buttonContainer: {
    paddingTop: '15px',
    paddingBottom: '15px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'flex-start',
  },
  initContainer: {
    backgroundImage: `url(${bgColorImg})`,
    backgroundSize: '100% 1000px',
    backgroundRepeat: 'no-repeat',
    minHeight: '1200px',
    paddingBottom: '50px',
    [theme.breakpoints.down('xl')]: {
      backgroundSize: '100% 900px',
      minHeight: "1000px",
    },
    [theme.breakpoints.down("md")]: {
      backgroundImage: `url(${bgColorMobile})`,
      backgroundSize: '100% 600px',
      minHeight: '650px',
    },
    [theme.breakpoints.down("sm")]: {
      backgroundSize: '100% 415px',
      minHeight: '500px',
    },
    [theme.breakpoints.down("450")]: {
      backgroundSize: '100% 315px',
      minHeight: "400px",
    },
    [theme.breakpoints.down("400")]: {
      backgroundSize: '100% 280px',
      minHeight: "350px",
    },
    [theme.breakpoints.down("350")]: {
      backgroundSize: '100% 240px',
      minHeight: "260px",
    },
  },
  sectiondark: {
    minHeight: "900px",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    backgroundImage: `url(${fondo})`,
    color: "#fff",
    backgroundSize: '100% 900px',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('xl')]: {
      backgroundSize: '100% 800px',
      minHeight: "825px",
    },
    [theme.breakpoints.down("md")]: {
      minHeight: '550px',
      backgroundSize: '100% 550px',
      backgroundImage: `url(${fondoMovil})`,
    },
    [theme.breakpoints.down("sm")]: {
      backgroundSize: '100% 375px',
      minHeight: "400px",
      backgroundImage: `url(${fondoMovil})`,
      display: 'block'
    },
    [theme.breakpoints.down("450")]: {
      backgroundSize: 'contain',
      minHeight: "300px",
    },
    [theme.breakpoints.down("400")]: {
      minHeight: "275px",
    },
    [theme.breakpoints.down("350")]: {
      minHeight: "240px",
    },
  },
  line: {
    color: '#1345A6'
  }
}))

export default Home