import InfoTwoToneIcon from '@mui/icons-material/Info';
import EmojiObjectsTwoToneIcon from '@mui/icons-material/EmojiObjects';
import BuildTwoToneIcon from '@mui/icons-material/Build';
import ContactMailTwoToneIcon from '@mui/icons-material/ContactMail';

const items = [
  {
    link: "#advantages",
    text: "Ventajas",
    icon: <EmojiObjectsTwoToneIcon fontSize='large' />
  },
  {
    link: "#pricing",
    text: "Precios",
    icon: <BuildTwoToneIcon fontSize='large' />
  },
  {
    link: "#testimonials",
    text: "Testimonios",
    icon: <ContactMailTwoToneIcon fontSize='large' />
  },
  {
    link: "#schedule",
    text: "Agendar Reunión",
    icon: <InfoTwoToneIcon fontSize='large' />
  },
  {
    link: "#contactUs",
    text: "Contactenos",
    icon: <ContactMailTwoToneIcon fontSize='large' />
  },
];

export default items;