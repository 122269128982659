import React from 'react';
import { makeStyles } from '@mui/styles';
import CardAdvantages from '../components/CardAdvantages/CardAdvantages';
// import CardAdvantageMovil from '../components/CardAdvantages/CardAdvantageMovil';
import { Grid, Typography, Hidden } from '@mui/material';
import { Box } from '@mui/system';
import img from "../img/dotsDarker.png";
import dotsMobile from '../img/dotsDarkerMobile.png';
// import imgMovil from "../img/dots2.png";
import card1 from '../img/cardImage1.png';
import card2 from '../img/cardImage2.png';
import card3 from '../img/cardImage3.png';

const Advantages = () => {
  const links = [
    {
      adventage: "Fácil Configuración",
      description: "Olvídate de la infraestructura, olvídate del código, enfócate en lo que de verdad le es util a ti o y tu negocio",
      image: card1
    },
    {
      adventage: "Interfaz amigable",
      description: "La interfaz ha sido diseñada considerando diversos niveles de habilidad, ya sea que nunca hayas escrito una línea de código o que tengas una amplia experiencia. Esto te permitirá probar tu idea de manera rápida y sencilla.",
      image: card2
    },
    {
      adventage: "¡Adiós a las cadenas!",
      description: "¡Supera tus límites sin temor alguno! Podrás integrar sin dificultad servicios externos, desarrollar tus propios componentes e incluso conectar tus aplicaciones móviles. Y como si eso no fuera suficiente, con nuestra función de exportación de código, podrás retomar tu proyecto exactamente desde donde lo dejaste. ¡No hay límites para tu crecimiento con nosotros!",
      image: card3
    },
  ];

  const classes = useStyles();
  return (
    <div className={`${classes.section}`} id="Ventajas" name="Ventajas">

      <Box className={classes.boxMainTitleSection}>
        {/* <Hidden only={['sm', 'xs']}>
          <Box className={classes.pictureTitleSection}>
            <img src={img} alt="Logo" className={classes.sectionCard} />
          </Box>
        </Hidden>
        <Hidden only={['md', 'xl', 'lg']}>
          <Box className={classes.pictureTitleSection}>
            <img src={imgMovil} alt="Logo" className={classes.sectionCard} />
          </Box>
        </Hidden> */}
        <Hidden smDown>
            <Box component="img" src={img} alt="dots" className={classes.imageStyle} />
          </Hidden>
          <Hidden smUp>
            <Box component="img" src={dotsMobile} alt="dots" className={classes.imageStyle} />
          </Hidden>
        <Box className={classes.boxZero}>
          <Typography >
            <b className={classes.textP1}>¿Por qué</b> <b className={classes.textP2}>Escogernos?</b>
          </Typography>
          <Typography>
            <b className={classes.textP}>Ventajas de trabajar con nosotros</b>
          </Typography>
        </Box>
      </Box>

      <Box className={classes.mainClassColor}>
        <Grid className={classes.mainClassColor} item xs={10} md={12}>
          <Grid className={classes.mainClassColor} container spacing={2}>
              {links.map((link) => (
                <Grid className={classes.mainClassColor} item key={link.adventage} xs={12} md={4} sm={12} lg={4} >
                  <CardAdvantages adventage={link.adventage} description={link.description} icon={link.image}></CardAdvantages>
                </Grid>
              ))}
            {/* <Hidden only={['sm', 'md', 'xl', 'lg']}>
              {links.map((link) => (
                <Grid item key={link.adventage} xs={12} md={4} sm={4}>
                  <CardAdvantageMovil adventage={link.adventage} description={link.description} icon={link.image}></CardAdvantageMovil>
                </Grid>
              ))}
            </Hidden> */}
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  mainClassColor: {
    backgroundColor: '#fff',
  },
  imageStyle: {
    width: '420px',
    height: '100px',
    [theme.breakpoints.down("md")]: {
      width: '300px',
      height: '90px',
    },
    [theme.breakpoints.down("sm")]: {
      width: 'auto',
      height: '40px',
    },
  },
  boxMainTitleSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 'auto',
    paddingTop: '2rem',
    marginBottom: '5rem',
  },
  pictureTitleSection: {
    display: 'flex',
    width: '30%',
    height: 'auto',
    [theme.breakpoints.down("sm")]: {
      width: '50%',
    }
  },
  section: {
    paddingTop: '4rem',
    minHeight: "auto",
    paddingBottom: '100px',
    color: "#29B2EF",
    fontFamily: 'Roboto',
    fontStyle: "normal",
    letterSpacing: "0em",
    backgroundColor: "#fff !important",
    [theme.breakpoints.down('md')]: {
      paddingTop: '2rem',
    },
  },
  sectionCard: {
    paddingTop: '5rem',
    paddingLeft: '5rem',
    paddingBottom: '3rem',
    paddingRight: '1rem',
    [theme.breakpoints.down("sm")]: {
      paddingLeft: '2.5rem',
      paddingRight: '0rem',
    },
  },
  textP: {
    fontWeight: 700,
    color: 'rgba(20, 73, 153, 1)',
    fontSize: "1em",
    lineHeight: "23px",
    fontStyle: "Bold",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.2em",
    },
    [theme.breakpoints.down("sm")]: {
      textAlign:'left',
      fontSize: "0.8em",
    },
  },
  textTitle: {
    fontWeight: 700,
    fontSize: "36px",
    lineHeight: "42px",
    fontStyle: "Bold"
  },
  boxZero: {
    width: 'auto',
    height: '100px',
    display: 'flex',
    marginLeft: '1rem',
    // alignItems: 'center',
    flexDirection: 'column',
    // alignContent: 'center',
    // justifyContent: 'center',
    [theme.breakpoints.down("md")]: {
      paddingLeft: '15px',
      height: '90px',
      alignContent: 'center',
      justifyContent: 'center',
      alignItems: 'center',
    },
    [theme.breakpoints.down("sm")]: {
      alignItems: 'flex-start',
      // width: '50%',
      height: '40px',
    },
  },
  textP1: {
    color: 'rgba(20, 73, 153, 1)',
    fontWeight: 700,
    fontSize: "2em",
    lineHeight: "33px",
    fontStyle: "Bold",
    [theme.breakpoints.down("md")]: {
      textAlign:'left',
      fontSize: "1.8em",
    },
    [theme.breakpoints.down("sm")]: {
      textAlign:'left',
      fontSize: "1.2em",
    },

  },
  textP2: {
    fontWeight: 300,
    color: 'rgba(20, 73, 153, 1)',
    fontSize: "2em",
    lineHeight: "33px",
    fontStyle: "normal",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.6em",
    },
    [theme.breakpoints.down("sm")]: {
      textAlign:'left',
      fontSize: "1.2em",
    },
  },
}))

export default Advantages