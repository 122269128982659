/* eslint-disable react-hooks/exhaustive-deps */
import React, { useLayoutEffect } from 'react'
import { useHistory } from 'react-router-dom';

const HashRedirect = () => {
  const { location } = useHistory();
  const { hash } = location;
  const domElement = document.getElementById(hash.substr(1));

  useLayoutEffect(() => {
    if (hash && domElement) {
      domElement.scrollIntoView({ behavior: "smooth" })
    }
  }, [hash]);
  return (
    <></>
  )
}

export default HashRedirect