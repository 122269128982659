import React, { useEffect, useState } from 'react';
import { Box, Button, Grid } from '@mui/material';
import Layout from '../layouts/Layout';
import { useParams, useHistory } from 'react-router-dom';
import Thumbnail from 'react-webpage-thumbnail';
import { getProjectTemplates } from 'api-lofty';
import CardTemplate from '../components/CardTemplate/cardTemplate';

const PreviewTemplate = () => {
    const { idTemplate } = useParams();
    const history = useHistory();
    const [templateDetails, setTemplateDetails] = useState(null);
    const [suggestedTemplates, setSuggestedTemplates] = useState([]);
    const [iframeVersion, setIframeVersion] = useState(0);

    useEffect(() => {
        const fetchTemplateDetails = async () => {
            try {
                const data = await getProjectTemplates();
                const selectedTemplate = data.data.find(template => template._id === idTemplate);
                if (selectedTemplate) {
                    setTemplateDetails(selectedTemplate);
                    setIframeVersion(prevVersion => prevVersion + 1);
                } else {
                    console.error(`No se encontró la plantilla con ID: ${idTemplate}`);
                }
            } catch (error) {
                console.error('Error fetching template details:', error);
            }
        };

        fetchTemplateDetails();
    }, [idTemplate]);

    useEffect(() => {
        const fetchSuggestedTemplates = async () => {
            try {
                const data = await getProjectTemplates();
                const filteredSuggestions = data.data.filter(template => template._id !== idTemplate);
                setSuggestedTemplates(filteredSuggestions);
            } catch (error) {
                console.error('Error fetching suggested templates:', error);
            }
        };

        if (templateDetails) {
            fetchSuggestedTemplates();
        }
    }, [templateDetails, idTemplate]);

    if (!templateDetails) {
        return <div>Cargando detalles de la plantilla...</div>;
    }

    const handleClickTemplate = (template) => {
        history.push(`/previewtemplate/${template._id}`, { templateDetails: template });
        window.scrollTo(0, 0);
      };

    return (
        <Layout>
            <Box sx={{ Height: '100vh', display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ flexGrow: 2, padding: '4rem', backgroundColor: '#F3F3F3' }}>
                    <Box sx={{ marginBottom: '1rem', fontSize: '1.5rem', display: 'flex', alignItems: 'center', marginLeft: '2rem' }}>
                        <a href="/templates" style={{ textDecoration: 'none', color: '#14569E', fontWeight: 700 }}>
                            Plantillas
                        </a>
                        <span style={{ color: '#14569E', fontWeight: 'bold', margin: '0 0.5rem' }}>{' '}&gt;{' '}</span>
                        <a href={`/previewtemplate/${templateDetails.id}`} style={{ textDecoration: 'none', color: '#31b5ef', fontWeight: 700 }}>
                            {templateDetails.name}
                        </a>
                    </Box>

                    <Box sx={{ borderBottom: '1px solid #C4C4C4', marginBottom: '2rem', marginLeft: '2rem', marginRight: '2rem' }}></Box>

                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexGrow: 0 }}>
                            {templateDetails.slug ? (
                                <Thumbnail
                                    key={`iframe-${iframeVersion}`}
                                    url={`https://${templateDetails.slug}.loftyapps.website`}
                                    width={400}
                                    height={600}
                                    iframeHeight={1920}
                                    iframeWidth={1080}
                                />
                            ) : (
                                <p>No se pudo cargar la previsualización del template (slug no disponible).</p>
                            )}
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Box>
                                <h1 style={{ color: '#333333', fontWeight: 'bold' }}>{templateDetails?.name}</h1>
                                <p style={{ color: '#333333' }}>{templateDetails?.description}</p>

                                <Box sx={{ marginBottom: '0.5rem' }}>
                                    <h3 style={{ color: '#828282', marginBottom: '0.2rem' }}>Precio</h3>
                                    <p style={{ fontSize: '2rem', fontWeight: 'bold', color: '#29B2EF', margin: '0' }}>
                                        HNL {templateDetails?.price.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                                    </p>
                                </Box>

                                <Box sx={{ borderBottom: '1px solid #C4C4C4', width: '50%', marginBottom: '1rem' }}></Box>

                                <Button
                                    variant="contained"
                                    sx={{ backgroundColor: '#201549', color: '#fff', '&:hover': { backgroundColor: '#180d3a' } }}
                                    href={`https://platform.loftyapps.com/dashboard/templates/${templateDetails._id || templateDetails?.id}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    disabled={!templateDetails?._id && !templateDetails?.id}
                                >
                                    Comprar
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>

                    {suggestedTemplates.length > 0 && (
                        <Box sx={{ marginTop: '6rem' }}>
                            <h2 style={{ textAlign: 'center', marginBottom: '2rem' }}>Sugerencias Similares</h2>
                            <Grid container spacing={2} sx={{ justifyContent: 'center' }}>
                                {suggestedTemplates.map((suggestion) => (
                                    <Grid key={suggestion._id} item xs={12} md={3}>
                                        <CardTemplate
                                            name={suggestion.name}
                                            description={suggestion.description}
                                            price={suggestion.price}
                                            url={suggestion.slug}
                                            idTemplate={suggestion._id}
                                            onClick={() => handleClickTemplate(suggestion)}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    )}
                </Box>
            </Box>
        </Layout>
    );
};

export default PreviewTemplate;