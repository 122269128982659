import React from 'react';
import { InlineWidget } from 'react-calendly';
import Layout from '../layouts/Layout';

const Calendar = () => (
  <Layout>
    <div
      style={{
        height: '100vh'
      }}
    >
      <InlineWidget
        url="https://calendly.com/argesware"
      />
    </div>
  </Layout>
);

export default Calendar;
