import React from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, Hidden, Typography } from '@mui/material';
import { Box } from '@mui/system';
import facebook from '../../img/icon/facebook.png';
import instagram from '../../img/icon/twitter.png';
import twiter from '../../img/icon/instagram.png';
import { Link } from 'react-router-dom';
import FooterIMG from '../../img/footerBG.png';



const Footer = () => {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <Grid container >
        <Grid item xs={12} style={{paddingTop:'15rem'}}>
          <Box
            className={classes.boxIcons}
          >
            <Hidden only={['xs']}>
            <Typography><Link to="/terms" style={{color: 'white'}}>Términos y condiciones</Link></Typography>
            <Typography><Link to="/privacy" style={{color: 'white'}}>Política de privacidad</Link></Typography>
            <Typography><b>Síguenos en</b> | nuestras redes</Typography>
            </Hidden>
            <Box>
              <a href="https://www.facebook.com/profile.php?id=100092092552915" className={classes.iconSocial}><img alt='Facebook' src={facebook}></img></a>
              <a href="https://www.instagram.com/loftyappslatam/" className={classes.iconSocial}><img alt='Instagram' src={instagram}></img></a>
              <a href="https://twitter.com/loftyApps" className={classes.iconSocial}><img alt='Twitter' src={twiter}></img></a>
            </Box>
          </Box>
          <hr style={{border:"0.5px solid #C4C4C4", minWidth:"250px",margin:'1rem'}} />
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              m: 1,
            }}
          >
            <Typography><b>Copyright {(new Date()).getFullYear()}</b> | Todos los Derechos reservados</Typography>
          </Box>
        </Grid>
      </Grid></div>
  )
}

const useStyles = makeStyles((theme) => ({
  section: {
    marginTop: '200px',
    minHeight: "419px",
    color: "#FFF",
    backgroundImage: `url(${FooterIMG})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
    [theme.breakpoints.down("md")]: {
      marginTop: '100px',
      backgroundSize: '100% 350px',
    },
    [theme.breakpoints.down("sm")]: {
      backgroundSize: '100% 275px',
    },
    [theme.breakpoints.down("xs")]: {
      backgroundSize: '100% 250px',
    },
    backgroundPositionY: 'bottom',
    fontSize:'14px',
    font:'Roboto',
  },
  sectionCard: {
    padding: "3rem"
  },
  boxIcons:{
    display: 'flex',
    justifyContent: 'space-around',
    [theme.breakpoints.down("sm")]: {
      justifyContent: 'center',
    },
  },
  iconSocial:{
    margin:'1rem'
  }
}))

export default Footer