import axios from '../config/axios';

export const url = '/api/contactpubliclanding';

export const addSuscription = ({ email, name, message }) => new Promise((resolve, reject) => {
  if (email) {
    axios.post(`${url}/add`, {
      email, name, message,
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((err) => reject(err));
  } else {
    reject({
      status: 'error',
      info: 'Correo no definido'
    });
  }
});
