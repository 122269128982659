import { createTheme } from '@mui/material/styles';

const theme=createTheme({
  palette:{
    primary:{
      light: '#4F9CF9',
      main: '#4F9CF9',
      dark: '#4F9CF9',
//      contrastText: '#201549',
    }
  },
  button:{
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: '13px',
    lineHeight: '15px',
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 400,
      md: 600,
      lg: 1200,
      xl: 1536,
    },
  },

})

export default theme;