import React, {useState} from 'react'
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const useStyles = makeStyles((theme) => ({
  tocContainer: {
    backgroundColor: 'rgba(237, 242, 252, 1)',
    padding: '0px 50px',
    // height: '100%',
    position: 'relative',
  },
  sectionLogo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: '10px',
    marginBottom: '25px',
  },
  titleStyle: {
    color: 'rgba(20, 86, 158, 1)',
    fontSize: '17px',
    fontWeight: 200,
    marginLeft: '15px',
  },
  headerSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    color: 'rgba(32, 21, 73, 1)',
    fontWeight: 800,
    fontSize: '14px',
    cursor: 'pointer',
    textDecoration: 'none',
  },
  subsectionStyle: {
    color: 'rgba(69, 69, 69, 1)',
    fontWeight: 400,
    fontSize: '14px',
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      color: 'rgba(20, 86, 158, 1) !important',
    },
  },
  listItem: {
    marginTop: '20px',
    marginBottom: '20px',
  }
}));

const RenderMenuItem = ({ section, hash }) => {
  const classes = useStyles();
  const handleToTop = () => {
    window.scrollTo(0, 0);
  };
  const [isRendered, setIsRendered] = useState(true);
  return (
    <Box key={section.id}>
      <Box className={classes.headerSection} component="div" onClick={() => setIsRendered(!isRendered)}>
        {isRendered ? (
          <KeyboardArrowDownIcon />
        ) : (
          <KeyboardArrowUpIcon />
        )}
        {section.title}
      </Box>
      {section.subsections.length > 0 && (
        <Box sx={{display: isRendered ? 'block' : 'none'}}>
        <ul>
          {section.subsections.map((subsection) => (
            <li
              key={subsection.id}
              style={{color: hash === subsection.id ? 'rgba(20, 86, 158, 1)' : 'rgba(228, 228, 228, 1)'}}
              className={classes.listItem}  
            >
              <Box
                component="a"
                className={classes.subsectionStyle}
                href={`#${subsection.id}`}
                onClick={handleToTop}
                style={{
                  color: hash === subsection.id ? 'rgba(20, 86, 158, 1)' : 'rgba(69, 69, 69, 1)',
                  fontWeight: hash === subsection.id ? 800 : 400,
                }}
              >
                {subsection.title}
              </Box>
            </li>
          ))}
        </ul>
        </Box>
      )}
      <Box sx={{backgroundColor: 'rgba(224, 224, 224, 1)', height: '1px', marginTop: '10px', marginBottom: '10px'}} />
    </Box>
  );

};

export default RenderMenuItem;
