import React from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, Typography, Hidden } from '@mui/material';
import { Box } from '@mui/system';
import img from "../img/dotsDarker.png";
import CardTestimonials from '../components/CardTestimonials/CardTestimonials';
import dotsMobile from '../img/dotsDarkerMobile.png';

const Testimonials = () => {
  const links = [
    {
      name: 'Francisco Morazán',
      title: "CEO Estudio Lukanka",
      description: "Como emprendedor me ha sido de mucha ayuda los servicios que me brindan y las excelentes asesorías técnicas que ofrecen. Permitiéndome enfocarme en experimentar, iterar y lanzar proyectos que resuelven problemas reales en menos tiempo.",
      url: 'https://argesware.com/assets/images/testimonial/francisco.jpg'
    },
    /* {
      name: 'Lorem Ipsum',
      title: "Lorem Ipsum ",
      description: "Vestibulum vel nunc ut massa maximus ultricies. Nulla eu volutpat arcu. Proin id pulvinar neque. Phasellus at ex ante. Donec non purus vel",
      url: ''
    },
    {
      name: 'Lorem Ipsum',
      title: "Lorem Ipsum ",
      description: "Vestibulum vel nunc ut massa maximus ultricies. Nulla eu volutpat arcu. Proin id pulvinar neque. Phasellus at ex ante. Donec non purus vel",
      url: ''
    }, */
  ];

  const classes = useStyles();
  return (
    <div className={`${classes.section}`} id="Testimonios">
      <Box className={classes.boxMainTitleSection} >
      {/* <Hidden only={['sm', 'xs']}>
          <Box className={classes.pictureTitleSection}>
            <img src={img} alt="Logo" className={classes.sectionCard} />
          </Box>
        </Hidden>
        <Hidden only={['md', 'xl', 'lg']}>
          <Box className={classes.pictureTitleSection}>
            <img src={imgMovil} alt="Logo" className={classes.sectionCard} />
          </Box>
        </Hidden> */}
        <Hidden smDown>
            <Box component="img" src={img} alt="dots" className={classes.imageStyle} />
          </Hidden>
          <Hidden smUp>
            <Box component="img" src={dotsMobile} alt="dots" className={classes.imageStyle} />
          </Hidden>
        <Box className={classes.boxZero}>
          <Typography>
            <b className={classes.textP1}>Testimonios de </b> <b className={classes.textP2}>Nuestros Clientes</b>
          </Typography>
          <Typography>
            <b className={classes.textP}>¿Que Dice la gente de nosotros?</b>
          </Typography>
        </Box>
      </Box>

      <Grid container spacing={2}>
        <Grid item  sm={2}>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Grid container spacing={2} justifyContent="center">
            {links.map((link) => (
              <Grid item key={link.title} xs={10} md={4} sm={6}>
                <CardTestimonials
                  name={link.name}
                  title={link.title}
                  description={link.description}
                  url={link.url} ></CardTestimonials>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  imageStyle: {
    width: '420px',
    height: '100px',
    [theme.breakpoints.down("md")]: {
      width: '300px',
      height: '90px',
    },
    [theme.breakpoints.down("sm")]: {
      width: 'auto',
      height: 'auto',
    },
  },
  boxMainTitleSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    marginBottom: '5rem',
    width: '100%',
    height: '200px',
    [theme.breakpoints.down("md")]: {
      height: '250px',
      marginBottom: '2rem',
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: '1.5rem',
      height: '150px',
    },
  },
  pictureTitleSection: {
    display: 'flex',
    width: '30%',
    height: 'auto',
    [theme.breakpoints.down("sm")]: {
      width: '50%',
    },
  },
  section: {
    minHeight: "auto",
    paddingBottom: '100px',
    color: "#29B2EF",
    background: "#FCFCFC",
    fontFamily: 'Roboto',
    fontStyle: "normal",
  },
  sectionCard: {
    paddingTop: '5rem',
    paddingLeft:'5rem',
    paddingBottom:'3rem',
    paddingRight:'1rem',
    [theme.breakpoints.down("sm")]: {
      paddingLeft: '2.5rem',
      paddingRight: '0rem',
    },
  },
  boxZero: {
    width: 'auto',
    paddingLeft: '2rem',
    color: 'rgba(20, 73, 153, 1)',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: '100px',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
      height: '90px',
    },
    [theme.breakpoints.down("sm")]: {
      alignItems: 'flex-start',
      justifyContent: 'center',
      paddingRight:'2rem',
      width: 'auto',
      paddingLeft: '1rem',
      alignContent: 'flex-start',
      paddingTop: '1px',
      height: '40px',
    },
  },
  textP: {
    fontWeight: 700,
    color: 'rgba(20, 73, 153, 1)',
    fontSize: "1.1em",
    lineHeight: "23px",
    fontStyle: "Bold",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.2em",
    },
    [theme.breakpoints.down("sm")]: {
      textAlign:'left',
      fontSize: "0.8em",
    },
  },
  textP1: {
    color: 'rgba(20, 73, 153, 1)',
    fontWeight: 700,
    fontSize: "2em",
    lineHeight: "33px",
    fontStyle: "Bold",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.5em",
    },
    [theme.breakpoints.down("sm")]: {
      textAlign:'left',
      fontSize: "1.1em",
    },
  },
  textP2: {
    fontWeight: 300,
    color: 'rgba(20, 73, 153, 1)',
    fontSize: "2em",
    lineHeight: "33px",
    fontStyle: "normal",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.5em",
    },
    [theme.breakpoints.down("sm")]: {
      textAlign:'left',
      fontSize: "1.1em",
    },
  },
}))

export default Testimonials