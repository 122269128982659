import React from 'react'
import { makeStyles } from '@mui/styles';
import { Grid, Hidden, Typography, Button } from '@mui/material';
import CardPlanDeployPrice from '../components/CardPricePlans/CardPlanDeployPrice';
import CardPricePlanTablet from '../components/CardPricePlans/CardPriceDeployTablet';
import { Box } from '@mui/system';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import img from "../img/dotsDarker.png";
import fondo from "../img/Rectangle.png";
// import imgMovil from "../img/dots2.png";
import fondoMovil from "../img/Rectangle32.png";
import dotsMobile from '../img/dotsDarkerMobile.png';

const PriceDeploy = ({ handleButtons }) => {
  const links = [
    {
      type: "Free",
      priceMonth: 0,
      priceYear: 0,
      dataTest: 50,
      collabs: 3,
      verPage: 3,
      storage: 'N/A',
      appweb: [
        {
          name: 'Ram',
          value: 'No Aplica',
        },
        {
          name: 'VCPU',
          value: 'No Aplica',
        },
      ],
      backoffice: [
        {
          name: 'Ram',
          value: 'No Aplica',
        },
        {
          name: 'VCPU',
          value: 'No aplica',
        },
      ],
      database: [
        {
          name: 'Ram',
          value: 'No aplica',
        },
        {
          name: 'Almacenamiento',
          value: 'No Aplica',
        },
        {
          name: 'VCPU',
          value: 'No Aplica',
        },
        {
          name: 'Asset Storage',
          value: '512 MB',
        },
      ],
      deploy: <CloseIcon style={{color: 'red'}} />,
    },
    {
      type: "Intro",
      priceMonth: 395.00,
      priceYear: 3950.00,
      dataTest: 200,
      collabs: 5,
      verPage: 5,
      storage: '5 GB',
      appweb: [
        {
          name: 'Ram',
          value: 'Compartida',
        },
        {
          name: 'VCPU',
          value: 'Compartida',
        },
      ],
      backoffice: [
        {
          name: 'Ram',
          value: 'Compartida',
        },
        {
          name: 'VCPU',
          value: 'Compartida',
        },
      ],
      database: [
        {
          name: 'Ram',
          value: 'Compartida',
        },
        {
          name: 'Almacenamiento',
          value: '5 GB',
        },
        {
          name: 'VCPU',
          value: 'Compartida',
        },
        {
          name: 'Asset Storage',
          value: '1 GB',
        },
      ],
      deploy: <CheckIcon style={{color: 'green'}} />,
    },
    {
      type: "Medio",
      priceMonth: 1055.00,
      priceYear: 10550.00,
      dataTest: 500,
      collabs: 10,
      verPage: 10,
      storage: '15 GB',
      appweb: [
        {
          name: 'Ram',
          value: '1 GB',
        },
        {
          name: 'VCPU',
          value: 'Compartida',
        },
      ],
      backoffice: [
        {
          name: 'Ram',
          value: '1 GB',
        },
        {
          name: 'VCPU',
          value: 'Compartida',
        },
      ],
      database: [
        {
          name: 'Ram',
          value: '1 GB',
        },
        {
          name: 'Almacenamiento',
          value: '15 GB',
        },
        {
          name: 'VCPU',
          value: 'Compartida',
        },
        {
          name: 'Asset Storage',
          value: '2 GB',
        },
      ],
      deploy: <CheckIcon style={{color: 'green'}} />,
    },
    {
      type: "Avanzado",
      priceMonth: 2385.00,
      priceYear: 23850.00,
      dataTest: 2000,
      collabs: 20,
      verPage: 20,
      storage: '30 GB',
      appweb: [
        {
          name: 'Ram',
          value: '2 GB',
        },
        {
          name: 'VCPU',
          value: '1',
        },
      ],
      backoffice: [
        {
          name: 'Ram',
          value: '2 GB',
        },
        {
          name: 'VCPU',
          value: '1',
        },
      ],
      database: [
        {
          name: 'Ram',
          value: '2 GB',
        },
        {
          name: 'Almacenamiento',
          value: '30 GB',
        },
        {
          name: 'VCPU',
          value: '1',
        },
        {
          name: 'Asset Storage',
          value: '4 GB',
        },
      ],
      deploy: <CheckIcon style={{color: 'green'}} />,
    },
  ];
  const classes = useStyles();
  return (
    <div className={`${classes.section}`}>
      <Box className={classes.boxMainTitleSection} >
        {/* <Hidden only={['sm', 'xs']}>
          <Box className={classes.pictureTitleSection}>
            <img src={img} alt="Logo" className={classes.sectionCard} />
          </Box>
        </Hidden>
        <Hidden only={['md', 'xl', 'lg']}>
          <Box className={classes.pictureTitleSection}>
            <img src={imgMovil} alt="Logo" className={classes.sectionCard} />
          </Box>
        </Hidden> */}
          <Hidden smDown>
            <Box component="img" src={img} alt="dots" className={classes.imageStyle} />
          </Hidden>
          <Hidden smUp>
            <Box component="img" src={dotsMobile} alt="dots" className={classes.imageStyle} />
          </Hidden>
          <Box className={classes.boxZero}>
            <Typography>
              <b className={classes.textP}>Suscripciones de</b>
            </Typography>
            <Typography>
              <b className={classes.textP2}>Cuenta</b>
            </Typography>
          </Box>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={1}>
        </Grid>
        <Grid item xs={10}>
          <Grid container spacing={2}>
            <Hidden only={['xs','md','xl','lg']}>
                {
                  links.map((item) => (
                    <Grid key={item.type} item xs={12} md={4} sm={12} className={classes.containercard}>
                      <CardPricePlanTablet
                        onClick={handleButtons}
                        type={item.type}
                        priceMonth={item.priceMonth}
                        priceYear={item.priceYear}
                        appweb={item.appweb}
                        backoffice={item.backoffice}
                        database={item.database}
                        descuentoEC={item.descuentoEC} purple={item.purple}
                        deployment={item.deploy}
                        collabs={item.collabs}
                        storage={item.storage}
                        dataTest={item.dataTest}
                        verPage={item.verPage}
                      />
                    </Grid>
                  ))
                }
            </Hidden>
            <Hidden only={['sm']}>
                {
                  links.map((item) => (
                    <Grid key={item.type} item lg={3} xl={3} xs={12} md={6} sm={12} className={classes.containercard}>
                      <CardPlanDeployPrice
                        onClick={handleButtons}
                        type={item.type}
                        priceMonth={item.priceMonth}
                        priceYear={item.priceYear}
                        collabs={item.collabs}
                        storage={item.storage}
                        dataTest={item.dataTest}
                        verPage={item.verPage}
                        appweb={item.appweb}
                        backoffice={item.backoffice}
                        database={item.database}
                        descuentoEC={item.descuentoEC}
                        deployment={item.deploy}
                      />
                    </Grid>
                  ))
                }
            </Hidden>
          </Grid>
        </Grid>
      </Grid>
      <Box className={classes.underContainer}>
      <Box className={classes.boxEnterpriseContainer}>
        <Box className={classes.enterpriseTitleArea}>
          <Box className={classes.enterpriseTitleStyle}>
            Plan de Acompañamiento y Enterprise
          </Box>
          <Box><hr style={{border:"1px dotted #29B2EF", minWidth:"150px"}} /></Box>
        </Box>
        <Box className={classes.enterpriseMoreInfoArea}>
          <Box className={classes.moreInfoTitle}>
            ¡Contáctate con Nosotros!
          </Box>
          <Box className={classes.moreInfoSubtitle}>
            ¡Con nuestro plan de acompañamiento tu negocio nunca estará solo!
          </Box>
          <Box className={classes.boxBtn}>
            <Button onClick={handleButtons} variant="contained" size="small" className={classes.btnInfo}>OBTENER INFORMACIÓN</Button>
          </Box>
        </Box>
      </Box>
      </Box>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  imageStyle: {
    width: '420px',
    height: '100px',
    [theme.breakpoints.down("md")]: {
      width: '300px',
      height: '90px',
    },
    [theme.breakpoints.down("sm")]: {
      width: 'auto',
      height: 'auto',
    },
  },
  boxMainTitleSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '200px',
    marginBottom: '5rem',
    [theme.breakpoints.down('md')]: {
      marginBottom: '3rem',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '2.7rem',
    },
  },
  pictureTitleSection: {
    display: 'flex',
    width: '30%',
    height: 'auto',
    [theme.breakpoints.down("sm")]: {
      width: '50%',
    }
  },
  section: {
    minHeight: "120vh",
    color: "#29B2EF",
    backgroundImage: `url(${fondo})`,
    backgroundSize: '100% 97%',
    backgroundRepeat: 'no-repeat',
    background: "#FCFCFC",
    fontFamily: 'Roboto',
    fontStyle: "normal",
    paddingBottom: '5rem',
    [theme.breakpoints.down("sm")]: {
      backgroundImage: `url(${fondoMovil})`,
      backgroundSize: '100% 95%',
      backgroundPosition: 'center top',
    },
  },
  sectionCard: {
    paddingTop: '5rem',
    paddingLeft: '5rem',
    paddingBottom: '3rem',
    paddingRight: '1rem',
    [theme.breakpoints.down("sm")]: {
      paddingLeft: '2.5rem',
      paddingRight: '0rem',
    },
  },
  textTitle: {
    fontWeight: 700,
    fontSize: "36px",
    lineHeight: "42px",
    fontStyle: "Bold"
  }, 
  textP: {
    fontWeight: 700,
    fontSize: "2em",
    // lineHeight: "33px",
    fontStyle: "Bold",
    color: 'rgba(20, 73, 153, 1)',
    [theme.breakpoints.down("md")]: {
      fontSize: "1.6em",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1em",
    },
  },
  textP2: {
    fontWeight: 300,
    fontSize: "2em",
    // lineHeight: "33px",
    fontStyle: "normal",
    color: 'rgba(20, 73, 153, 1)',
    [theme.breakpoints.down("md")]: {
      fontSize: "1.6em",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1em",
    },
  },
  containercard: {
    //TO MODIFY
    paddingTop: "9rem",
    display: 'flex',
    justifyContent: 'center',
    // backgroundColor: 'rgba(255, 255, 255, 1)',
    // [theme.breakpoints.down('md')]: {
    //   backgroundColor: 'transparent',
    // },
  },
  boxZero: {
    width: 'auto',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    marginLeft: '1rem',
    justifyContent: 'flex-start',
    alignContent: 'center',
    height: '100px',
    [theme.breakpoints.down("md")]: {
      height: '90px',
    },
    [theme.breakpoints.down("sm")]: {
      alignItems: 'flex-start',
      // width: '50%',
      height: '40px',
    },
  },
  underContainer: {
    marginTop: '30px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  boxEnterpriseContainer: {
    width: '600px',
    border: '5px solid',
    borderColor: '#fff',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: { width: '500px'},
    [theme.breakpoints.down('sm')]: { width: '400px'}
  },
  enterpriseTitleArea: {
    backgroundColor: 'rgba(32, 21, 73, 1)',
    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    padding: '15px',
  },
  enterpriseTitleStyle: {
    fontSize: '25px',
    fontWeight: 'bold',
  },
  enterpriseMoreInfoArea: {
    border: '2px solid',
    borderColor: 'rgba(32, 21, 73, 1)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    padding: '15px',
    backgroundColor: '#fff',
  },
  moreInfoTitle: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: 'rgba(41, 33, 81, 1)',
  },
  moreInfoSubtitle: {
    color: 'rgba(41, 33, 81, 1)',
    textAlign: 'center',
  },
  boxBtn: {
    paddingTop: '2rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems:'center',
  },
  btnInfo:{
    width: "195px",
    height: "30px",
    borderRadius: "5px",
    fontSize: "13px",
    lineHeight: "15px",
  },
}));

export default PriceDeploy;
