import React from 'react';
import Layout from '../layouts/Layout';
import '../styles/comingSoon.css';
import coming_soon_bg from '../img/coming_soon_bg.png';
import coming_soon_logo from '../img/coming_soon_logo.png';


const NewComingSoon = ({ history }) => {
  return (
    <Layout>
      <div className="main-cs-container"
        style={{
          backgroundImage: `url(${coming_soon_bg})`,
          backgroundPositionX: 0,
          backgroundPositionY: '80%',
        }}>
        <img className="cs-lofty-logo" src={coming_soon_logo} alt="lofty-logo" />
        <div className="main-cs-title">
          ¡MUY PRONTO!
        </div>
        <div className="main-cs-subtitle">
          Este sitio esta en construcción, próximamente estaremos listos para comenzar
        </div>
      </div>
    </Layout>
  );
}

export default NewComingSoon;
