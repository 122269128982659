import { Box, Divider, Drawer, Hidden, IconButton, List, ListItem, ListItemIcon, } from '@mui/material'
import React, { useState } from 'react'
import MenuIcon from '@mui/icons-material/Menu';
import { makeStyles } from '@mui/styles';
import logo from "../../img/logoInit.png";
import CancelIcon from '@mui/icons-material/Cancel';
import { Link } from 'react-router-dom';


const Navbar = ({ items }) => {

  const classes = useStyles();
  const [open, setOpen] = useState(false);

  return (
    <div className={classes.root}>
      <Hidden mdUp>
        <div className={classes.menuToolbarApp}>
          <Box component="img" className={classes.imgStyle} src={logo} alt="Logo" />
          <IconButton edge="end" className={classes.menuButton} onClick={
            () => setOpen(!open)
          }>
            <MenuIcon className={classes.menuIconApp} />
          </IconButton>
        </div>
        <Drawer anchor='right' open={open} onClose={() => setOpen(false)} >
          <IconButton edge="end" onClick={
            () => setOpen(false)
          } style={{ padding: '2rem' }}>
            <CancelIcon fontSize='large' className={classes.cancelIcon} />
          </IconButton>
          <Divider />
          <List className={classes.menuApp} >
            {items.map(({ link, text, icon }, index) => (
              <Link
                key={index}
                className={classes.sidebar}
                to={link}
                activeClass="active"
                smooth={true}
                duration={500}
                offset={-70}
                onClick={
                  () => setOpen(false)
                }>
                <ListItem component="h5">
                  <span>
                    <ListItemIcon>
                      {icon}
                    </ListItemIcon>
                  </span>
                  {text}
                </ListItem>
              </Link>
            ))}
          </List>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <div className={classes.toolbar} >
          <img src={logo} className={classes.logo} alt="Logo" />
          <List className={classes.menu}>
            {items.map(({ link, text }, index) => (
              <Link
                className={classes.menuItemsApp}
                key={index}
                to={link}
                activeClass="active"
                smooth={true}
                duration={500}
                offset={-70}>{text}</Link>
            ))}
          </List>
        </div>
      </Hidden>
    </div>

  )
}

const useStyles = makeStyles((theme) => ({
  imgStyle: {
    width: 'auto',
    height: 'auto',
    objectFit: "cover",
    "&:hover": {
      cursor: "pointer"
    }
  },
  root: {
    backgroundColor: "rgba(20, 73, 153, 1)",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 999,
  },
  logo: {
    width: "auto",
    height: "auto",
    marginLeft: '20px',
    marginRight: '25px',
    objectFit: "contain",
    "&:hover": {
      cursor: "pointer"
    }
  },
  toolbar: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    height: "100px",
    padding: "0.5rem",
    fontFamily: 'Roboto',
  },
  menu: {
    "& a": {
      color: "#FFF",
      marginLeft: theme.spacing(3),
      fontFamily: 'Roboto',
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "21px",
    },
    "& a:hover ": {
      cursor: "pointer",
      color: "#FFF",
      borderBottom: "3px solid #FFF",
    }
  },
  menuApp:{
    width:'75vw'
  },
  menuButton: {
    position: "absolute",
    top: 0,
    right: 10,
    marginRight: '1rem!important',
  },
  menuIconApp: {
    color: "#FFF",
    width: "40px !important",
    height: "40px !important"
  },
  menuLogoApp: {
    minWidth: "15rem",
    minHeight: "10rem",
    objectFit: "contain",
    "&:hover": {
      cursor: "pointer"
    }
  },
  menuToolbarApp: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100px",
    paddingLeft: "2rem",
    fontFamily: 'Roboto',
  },
  cancelIcon: {
    color: "#29B2EF",
    position: "absolute",
    top: 0,
    right: 10,
    marginTop: "1rem",
    marginRight: '1rem'
  },
  menuItemsApp: {
    color: '#201549',
  }

}))



export default Navbar