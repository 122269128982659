export const pricedeploy = [
  {
    type: "Free",
    priceMonth: 0,
    priceYear: 0,
    dataTest: 50,
    collabs: 3,
    verPage: 3,
    storage: 'N/A',
    appweb: [
      {
        name: 'Ram',
        value: 'No Aplica',
      },
      {
        name: 'VCPU',
        value: 'No Aplica',
      },
    ],
    backoffice: [
      {
        name: 'Ram',
        value: 'No Aplica',
      },
      {
        name: 'VCPU',
        value: 'No aplica',
      },
    ],
    database: [
      {
        name: 'Ram',
        value: 'No aplica',
      },
      {
        name: 'Almacenamiento',
        value: 'No Aplica',
      },
      {
        name: 'VCPU',
        value: 'No Aplica',
      },
      {
        name: 'Asset Storage',
        value: '512 MB',
      },
    ],
  },
  {
    type: "Intro",
    priceMonth: 395.00,
    priceYear: 3950.00,
    dataTest: 200,
    collabs: 5,
    verPage: 5,
    storage: '5 GB',
    appweb: [
      {
        name: 'Ram',
        value: 'Compartida',
      },
      {
        name: 'VCPU',
        value: 'Compartida',
      },
    ],
    backoffice: [
      {
        name: 'Ram',
        value: 'Compartida',
      },
      {
        name: 'VCPU',
        value: 'Compartida',
      },
    ],
    database: [
      {
        name: 'Ram',
        value: 'Compartida',
      },
      {
        name: 'Almacenamiento',
        value: '5 GB',
      },
      {
        name: 'VCPU',
        value: 'Compartida',
      },
      {
        name: 'Asset Storage',
        value: '1 GB',
      },
    ],
  },
  {
    type: "Medio",
    priceMonth: 1055.00,
    priceYear: 10550.00,
    dataTest: 500,
    collabs: 10,
    verPage: 10,
    storage: '15 GB',
    appweb: [
      {
        name: 'Ram',
        value: '1 GB',
      },
      {
        name: 'VCPU',
        value: 'Compartida',
      },
    ],
    backoffice: [
      {
        name: 'Ram',
        value: '1 GB',
      },
      {
        name: 'VCPU',
        value: 'Compartida',
      },
    ],
    database: [
      {
        name: 'Ram',
        value: '1 GB',
      },
      {
        name: 'Almacenamiento',
        value: '15 GB',
      },
      {
        name: 'VCPU',
        value: 'Compartida',
      },
      {
        name: 'Asset Storage',
        value: '2 GB',
      },
    ],
  },
  {
    type: "Avanzado",
    priceMonth: 2385.00,
    priceYear: 23850.00,
    dataTest: 2000,
    collabs: 20,
    verPage: 20,
    storage: '30 GB',
    appweb: [
      {
        name: 'Ram',
        value: '2 GB',
      },
      {
        name: 'VCPU',
        value: '1',
      },
    ],
    backoffice: [
      {
        name: 'Ram',
        value: '2 GB',
      },
      {
        name: 'VCPU',
        value: '1',
      },
    ],
    database: [
      {
        name: 'Ram',
        value: '2 GB',
      },
      {
        name: 'Almacenamiento',
        value: '30 GB',
      },
      {
        name: 'VCPU',
        value: '1',
      },
      {
        name: 'Asset Storage',
        value: '4 GB',
      },
    ],
  },
];