import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import Thumbnail from 'react-webpage-thumbnail';
import { Button } from '@mui/material';
import { useHistory } from 'react-router-dom';

const CardTemplate = ({
  url,
  price,
  name,
  description,
  idTemplate,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const handlePreview = () => {
    history.push({
      pathname: `/previewtemplate/${idTemplate}`,
      state: {
        templateDetails: {
          id: idTemplate,
          name,
          description,
          price,
          url,
        },
      },
    });
  };

  return (
    <Box component="div" className={classes.boxContainer}>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ display: 'flex', overflow: 'hidden', width: 350 }}>
          <Thumbnail
            url={`https://${url}.loftyapps.website`}
            width={350}
            height={450}
            iframeHeight={1920}
            iframeWidth={1080}
          />
        </div>
      </div>
      <div style={{marginTop: 20, marginBottom: 20}}>
        <div style={{height: 75, overflowY: 'hidden'}} className={classes.textStyle}>
          {
            name.length > 100 ? `${name.substring(0, 100)} ...` : name
          }
        </div>
        <div style={{marginTop: 25, color: '#29B2EF'}} className={classes.textStyle}>
          HNL {price ? price.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : 'Precio no disponible'}
        </div>
      </div>
      <div className={classes.descriptionStyle}>
        {
          description.length > 255 ? `${description.substring(0, 255)} ...` : description
        }
      </div>
      <Box component="div" className={classes.sectionContainer}>
        <Button
          className={classes.buttonPrevStyle}
          variant="outlined"
          onClick={handlePreview}
        >
          Previsualizar
        </Button>
        <Button
          className={classes.buttonBuyStyle}
          variant="outlined"
          href={`https://platform.loftyapps.com/dashboard/templates/${idTemplate}`}
          target="_blank"
        >
          Comprar
        </Button>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    minHeight: '350px',
    borderRadius: '10px',
    backgroundColor: '#fff',
    padding: '20px',
    [theme.breakpoints.between('380', '400')]: { //breakpoints para definir el tamaño de la tarjeta dependiendo del tamaño de la pantalla
      width: '300px',
      margin: '0px 0px 0px -20px',
    },
    [theme.breakpoints.between('360', '380')]: { //breakpoints para definir el tamaño de la tarjeta dependiendo del tamaño de la pantalla
      width: '280px',
      margin: '0px 0px 0px -20px',
    },
    [theme.breakpoints.between('340', '360')]: { //breakpoints para definir el tamaño de la tarjeta dependiendo del tamaño de la pantalla
      width: '260px',
      margin: '0px 0px 0px -20px',
    },
    [theme.breakpoints.between('320', '340')]: { //breakpoints para definir el tamaño de la tarjeta dependiendo del tamaño de la pantalla
      width: '240px',
      margin: '0px 0px 0px -20px',
    },
  },
  sectionContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '20px',
    marginBottom: '20px',
  },
  textStyle: {
    fontSize: '32px',
    fontWeight: 700,
    [theme.breakpoints.down('1440')]: {
      fontSize: '20px',
    },
    [theme.breakpoints.down('1025')]: {
      fontSize: '18px',
    },
  },
  descriptionStyle: {
    fontSize: '18px',
    fontWeight: 400,
    marginTop: '15px',
    marginBottom: '15px',
    textOverflow: 'ellipsis',
    height: 150,
    overflow: 'hidden',
    [theme.breakpoints.down('1440')]: {
      fontSize: '14px',
    },
  },
  buttonPrevStyle: {
    border: '1px solid rgba(20, 86, 158, 1) !important',
    padding: '15px !important',
    color: 'rgba(20, 86, 158, 1) !important',
    backgroundColor: '#fff !important',
    borderRadius: '5px !important',
    fontSize: '12px !important',
  },
  buttonBuyStyle: {
    border: '1px solid rgba(32, 21, 73, 1) !important',
    padding: '15px !important',
    color: '#fff !important',
    backgroundColor: 'rgba(32, 21, 73, 1) !important',
    borderRadius: '5px !important',
    fontSize: '12px !important',
  },
}));

export default CardTemplate;
