import { Typography, Box, Hidden} from '@mui/material';
import React from 'react';
// import work from "../img/work.png";
import { makeStyles } from '@mui/styles';
import dots from '../img/dotsDarker.png';
import vid from '../video/LoftyApps.mp4'
import dotsMobile from '../img/dotsDarkerMobile.png';

const AfterInitSection = () => {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.mainSection}>
        <Box component="div" className={classes.titleSection}>
          <Hidden smDown>
            <Box component="img" src={dots} alt="dots" className={classes.imageStyle} />
          </Hidden>
          <Hidden smUp>
            <Box component="img" src={dotsMobile} alt="dots" className={classes.imageStyle} />
          </Hidden>
          <Box component="div" style={{ marginLeft: '15px' }}>
            <Typography>
              <b className={classes.textTitle}>
                Una visión
              </b>
              <Box component="span" className={classes.textTitle}>
                al futuro
              </Box>
            </Typography>
          </Box>
        </Box>
        <Box component="div" className={classes.videoSection}>
          <Box className={classes.videoContainer}>
            <video controls src={vid} className={classes.videoStyle}>
              <track src={vid} />
            </video>
          </Box>
          <Box component="div" className={classes.videoTextSection}>
            Te brindamos una plataforma que facilita la transformación de tus ideas en software de forma directa y efectiva.
          </Box>
        </Box>
      </Box>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  imageStyle: {
    width: '420px',
    height: '100px',
    [theme.breakpoints.down("md")]: {
      width: '300px',
      height: '90px',
    },
    [theme.breakpoints.down("sm")]: {
      width: 'auto',
      height: '50px',
    },
  },
  mainSection: {
    height: 'auto',
    width: '100%',
  },
  titleSection: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    // alignContent: 'center',
    // alignItems: 'center',
    padding: '3rem',
  },
  videoContainer: {
    // backgroundColor: 'red',
    width: '60%',
    display: 'flex',
    [theme.breakpoints.down("md")]: {
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      paddingLeft: '5px',
      paddingRight: '5px',
    },
    [theme.breakpoints.down("sm")]: {
      width: 'auto',
    }

  },
  videoStyle: {
    width: '900px',
    [theme.breakpoints.down("xl")]: {
      width: '800px',
    },
    [theme.breakpoints.down("lg")]: {
      width: '100%',
    },
    [theme.breakpoints.down("md")]: {
      width: '95%',
    },
    [theme.breakpoints.down("sm")]: {
      width: '90%',
    },
    // [theme.breakpoints.down("sm")]: {
    //   width: '360px',
    // },
  },
  videoSection: {
    width: '100%',
    height: '600px',
    backgroundColor: 'rgba(244, 244, 244, 1)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    padding: '2rem',
    [theme.breakpoints.down("md")]: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      padding: '0px',
    },
  },
  videoTextSection: {
    backgroundColor: 'rgba(255,255,255,1)',
    color: 'rgba(20, 73, 153, 1)',
    width: '30%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    padding: '3rem',
    height: '480px',
    boxShadow: '5px 5px linear-gradient(112.1deg, rgb(32, 38, 57) 11.4%, rgb(63, 76, 119) 70.2%)',
    fontSize: '21px',
    fontWeight: '700',
    fontFamily: 'Roboto',
    [theme.breakpoints.down("md")]: {
      width: '100%',
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: '14px',
      paddingLeft: '1.5rem',
      height: '240px',
    },
  },
  textTitle: {
    fontFamily: 'Roboto',
    color: 'rgba(20, 73, 153, 1)',
    fontStyle: "normal",
    fontSize: "3.5rem",
    letterSpacing: "0em",
    paddingRight: "1rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "2.4em",
      paddingLeft: '15px',
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: '1.3em',
      paddingLeft: '0.5rem'
    },
  },
}))

export default AfterInitSection;
