import { Button } from '@mui/material'
import React from 'react'
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';

const CardExportCode = ({ name, price, iconImg, onClick}) => {
  const classes = useStyles();
  return (
    <Box component="div" className={classes.cardContainer}>
      <Box component="img" src={iconImg} style={{margin: '10px'}} />
      <Box component="div" className={classes.titleCardStyle}>
        {name}
      </Box>
      <Box component="div" className={classes.priceSection}>
        <Box component="div" className={classes.priceTag}>
          {price}
        </Box>
        <Box component="div" className={classes.unitTag}>
          c/u
        </Box>
      </Box>
      <Button variant="contained" size="large" className={classes.buttonStyle}>
        Obtener Información
      </Button>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    borderRadius: '0px 0px 10px 10px',
    borderTop: '2px solid #29B2EF',
    background: '#FFF',
    display: 'flex',
    flexDirection: 'column',
    padding: '10px',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '320px',
  },
  titleCardStyle: {
    color: '#201549',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    textTransform: 'capitalize',
    margin: '10px',
  },
  priceSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    margin: '10px',
  },
  priceTag: {
    color: '#29B2EF',
    fontSize: '35px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    textTransform: 'capitalize',
  },
  unitTag: {
    color: '#29B2EF',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    textTransform: 'capitalize',
  },
  buttonStyle: {
    borderRadius: '5px',
    background: '#29B2EF',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    color: '#201549',
    textAlign: 'center',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    margin: '10px',
  },
}))

export default CardExportCode