import React from 'react';
import Layout from '../layouts/Layout';
import iconPlanDesc from '../img/price_plan/Online_Support.png';
import CardPricePlan from '../components/CardPricePlan';
import '../styles/pricePlan.css';

const links = [
  {
    type: "Free",
    price: '0',
    collaborators: 3,
    storage: 'N/A',
    included: [
      { feats: "3 colaboradores para administrar tu proyecto" },
      { feats: "500 MB para almacenamiento de imágenes estáticas" },
    ]
  },
  {
    type: "Intro",
    price: '3,950',
    collaborators: 5,
    storage: '5 GB',
    included: [
      { feats: "5 colaboradores para administrar tu proyecto" },
      { feats: "1 GB para almacenamiento de imágenes estáticas" },
      { feats: "5 GB de almacenamiento para tu base de datos" },
    ]
  },
  {
    type: "Medio",
    price: '10,550',
    collaborators: 10,
    storage: '15 GB',
    included: [
      { feats: "10 colaboradores para administrar tu proyecto" },
      { feats: "2 GB para almacenamiento de imágenes estáticas" },
      { feats: "15 GB de almacenamiento para tu base de datos" },
    ]
  },
  {
    type: "Avanzado",
    price: '23,850',
    collaborators: 20,
    storage: '30 GB',
    included: [
      { feats: "20 colaboradores para administrar tu proyecto" },
      { feats: "4 GB para almacenamiento de imágenes estáticas" },
      { feats: "30 GB de almacenamiento para tu base de datos" },
    ]
  },
];

const NewPricePlan = ({ history }) => {
  return (
    <Layout>
      <header className='header-price-plan-landing-style'>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginTop: '20px',
            marginBottom: '20px',
          }}
        >
          <div className='title-price-plan-landing'>
            Suscripciones para todos los usos
          </div>
          <div className='subtitle-price-plan-landing'>
            Potencia tu sitio web o aplicación sin programar. Suscríbete hoy y obtén hosting de alto rendimiento. ¡Crece sin límites!
          </div>
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginTop: '20px',
            marginBottom: '20px',
          }}
        >
          <div className='card-blue-plan-subscription'>
            <img src={iconPlanDesc} alt="icon" />
            <div className='middle-section-card-blue-plan-subscription-info-area'>
              <div className='middle-section-card-blue-plan-subscription-title'>
                Plan de Acompañamiento y Enterprise
              </div>
              <div className='middle-section-card-blue-plan-subscription-divisor' />
              <div className='middle-section-card-blue-plan-subscription-subtitle'>
                ¡Con nuestro plan de acompañamiento tu negocio nunca estará solo!
              </div>
            </div>
            <div className='middle-section-card-blue-plan-subscription-button-container'>
              <button
                className='middle-section-card-blue-plan-subscription-button'
                onClick={() => {
                  history.push('/calendar')
                }}
              >
                ¡Contáctate con Nosotros!
              </button>
            </div>
          </div>
        </div>
      </header>
      <main>
        <div 
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginTop: '20px',
            marginBottom: '100px',
          }}
        >
          {
            links.map((index) => (
              <CardPricePlan
                collaborators={index.collaborators}
                included={index.included}
                price={index.price}
                storage={index.storage}
                type={index.type}
                onClick={() => {
                  history.push('/calendar')
                }}
              />
            ))
          }
        </div>
      </main>
    </Layout>
  );
}

export default NewPricePlan;