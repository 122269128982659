// import { Button, Card, CardContent } from '@mui/material';
import React from 'react'
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';

const ArticleCard = ({
  image,
  title,
  description,
  date,
  onClick,
}) => {
  const classes = useStyles();
  return (
    <Box component="div" className={classes.boxContainer} onClick={() => onClick()}>
      <Box component="div" className={classes.infoArticleContainer}>
        <Box component="div" className={classes.dateStyle}>
          {date}
        </Box>
        <Box component="div" className={classes.titleStyle}>
          {title}
        </Box>
        <Box component="div" className={classes.descriptionStyle}>
          {description}
        </Box>
      </Box>
      <Box component="img" src={image} className={classes.imageStyle} />
    </Box>
  )
}


const useStyles = makeStyles((theme) => ({
  boxContainer: {
    display: 'flex',
    padding: '16px',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    // gap: 16px;
    borderRadius: '12px',
    border: '1px solid #E8E8EA',
    background: '#fff',
    [theme.breakpoints.down("600")]: {
      flexDirection: 'column-reverse',
    },
    margin: '10px',
  },
  infoArticleContainer: {
    width: '70%',
    [theme.breakpoints.down("600")]: {
      width: '100%',
    },
  },
  imageStyle: {
    height: '220px',
    width: '28%',
    margin: '5px',
    objectFit: 'cover',
    borderRadius: '10px',
    [theme.breakpoints.down("1024")]: {
      height: '180px !important',
    },
    [theme.breakpoints.down("600")]: {
      height: '178px !important',
      width: '100%',
    },
  },
  dateStyle: {
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '120%', /* 15.6px */
    textTransform: 'uppercase',
    color: '#667085',
    marginBottom: '15px',
    [theme.breakpoints.down("600")]: {
      fontSize: '10px',
    },
  },
  titleStyle: {
    color: '#1D2939',
    /* Heading/h3/Semibold */
    // font-family: Work Sans;
    fontSize: '26px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '130%', /* 33.8px */
    [theme.breakpoints.down("1024")]: {
      fontSize: '17px',
    },
    [theme.breakpoints.down("600")]: {
      fontSize: '15px',
    },
  },
  descriptionStyle: {
    color: '#475467',
    /* Heading/h3/Semibold */
    // font-family: Work Sans;
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '150%', /* 33.8px */
    [theme.breakpoints.down("1024")]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down("600")]: {
      fontSize: '12px',
    },
  },
}))

export default ArticleCard;

